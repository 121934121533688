(function ($) {
	'use strict';
	


	$('.block-links__scroll').on('click', function(e){
		e.preventDefault();
		$('html, body').animate({ 
			scrollTop: $(this).offset().top + $(this).height()
		}, 500, 'linear');

	})
}(jQuery));


