// When the window has finished loading create our google map below
var pfi,
    provided_pfi,
    address,
    related = {
        parcels: {
            list: [],
            count: 0
        },
        properties: {
            list: [],
            count: 0
        },
        steps: {
            step2: {
                items: [-1],
                requests: []
            },
            step3: {
                items: [-1],
                requests: []
            }
        },
        address: {
            properties: [],
            parcels: [],
            request: []
        }
    },
    property,
    magicKey,
    search_type,
    pfi_REST,
    retain_search_type,
    key_REST,
    get_address_REST,
    parcel_REST,
    address_REST,
    multiple_address_REST,
    multiple_parcel_REST,
    legislative_REST,
    utilites_REST,
    lga_REST,
    map_endpoint,
    related_property_REST,
    related_parcel_REST,
    geoplex_REST;

/**
 * Init
 */
if ($(".property-dashboard").length > 0) {

    var property_pfi_REST = $('#property-dashboard').data('property-pfi');
    var parcel_pfi_REST = $('#property-dashboard').data('parcel-pfi');

    var property_key_REST = $('#property-dashboard').data('property-key');
    var parcel_key_REST = $('#property-dashboard').data('parcel-key');

    var crown_key_REST = $('#property-dashboard').data('crown-key'); 

    provided_pfi = $('#property-dashboard').data('provided_pfi');
    property = $('#property-dashboard').data('value');
    magicKey = $('#property-dashboard').data('magickey');
    address_REST = $('#property-dashboard').data('address');
    parcel_REST = $('#property-dashboard').data('parcel');
    multiple_address_REST = $('#property-dashboard').data('multiple-address');
    multiple_parcel_REST = $('#property-dashboard').data('multiple-parcel');
    geoplex_REST = $('#property-dashboard').data('geoplex');
    legislative_REST = $('#property-dashboard').data('legislative');
    utilites_REST = $('#property-dashboard').data('utilites');
    lga_REST = $('#property-dashboard').data('lga');
    map_endpoint = $('#property-dashboard').data('map-endpoint');
    related_property_REST = $('#property-dashboard').data('related-property');
    related_parcel_REST = $('#property-dashboard').data('related-parcel');

    // Init property dashboard
    if (magicKey !== '') {
        pdInit(property, null, magicKey);
    } else if (provided_pfi !== '') {
        pdInit(property, provided_pfi, null);
    } else {
        $('.search-header__change-plnning-btn').click();
    }

    // Perform additional actions
    additionalActions();
}

/**
 * Any additional actions
 */
function additionalActions() {

    // VPP-3709 - clear input on close
    $('.lga-popup-container__close').on('click', function () {
        $('#search__query-autocomplete').typeahead('val', '');
    });
}

/**
 * Set global variables - search_type based on property name from suggestions and PFI REST
 * @param {string} property property or parcel name from the suggestions autocomplete
 */
function setSearchType(property) {
    let sanitizedProperty = property.replace(/\+/g, ' ');
    // search_type = ((/\s/g).test(sanitizedProperty)) ? 'property' : 'parcel';
    // pfi_REST = (search_type == 'property') ? property_pfi_REST : parcel_pfi_REST;
    // key_REST = (search_type == 'property') ? property_key_REST : parcel_key_REST;

    // get_address_REST = (search_type == 'property') ? address_REST : parcel_REST;

    search_type = 'property'

    if( /\\/g.test(sanitizedProperty) ) search_type = 'parcel';

    if(search_type == 'parcel' && /\s/g.test(sanitizedProperty)) search_type = 'crown';

    if( ! /\s/g.test(sanitizedProperty) ) search_type = 'parcel';

    console.log(search_type + "  " + sanitizedProperty);

    console.log(crown_key_REST)

    if (search_type == 'crown') retain_search_type = 'crown';

    key_REST = property_key_REST;

    if (search_type == 'parcel') key_REST = parcel_key_REST;

    if (search_type == 'crown') key_REST = crown_key_REST;

    pfi_REST = property_pfi_REST;

    if (search_type == 'parcel') pfi_REST = parcel_pfi_REST;

    if (search_type == 'crown') pfi_REST = parcel_pfi_REST;

    get_address_REST = address_REST;

    if (search_type == 'parcel') get_address_REST = parcel_REST;

    if (search_type == 'crown') get_address_REST = parcel_REST;

    if (search_type == 'crown') search_type = 'parcel';
}

/**
 * Get LGA code from address_REST request response data
 * @param {string} type 'property' or 'parcel'
 * @param {JSON} jsonData json response from address_REST
 */
function getLGACode(type, jsonData) {
    if (type === 'property') {
        return jsonData.address.LGA_CODE;
    } else {
        return jsonData.features[0].attributes.PARCEL_LGA_CODE;
    }
}

/**
 * Get Geometry from address_REST request response data
 * @param {string} type 'property' or 'parcel'
 * @param {JSON} jsonData json response from address_REST
 */
function getGeometry(type, jsonData) {
    if (type === 'property') {
        return jsonData.geometry;
    } else {
        return jsonData.features[0].geometry;
    }
}

/**
 * Generic helper to perform REST XHR calls
 * @param {string} gateway REST URL
 * @param {string} query value for GET paramter "query"
 * @return {Promise} Returns promise that is resolved on XHR completed
 */
function getData(gateway, query) {

    return new Promise(function (resolve, reject) {

        // New XHR
        let xhr = new XMLHttpRequest();

        //xhr.responseType = 'json';
        xhr.overrideMimeType("application/json");

        // Events
        xhr.onload = function () {
            if (this.status >= 200 && this.status < 300) {
                resolve(JSON.parse(xhr.response));
            } else {
                reject({});
            }
        };
        xhr.onerror = function () {
            reject({});
        };

        // Go
        xhr.open('GET', `${gateway}?query=${query}`);
        xhr.send();

    });

};

/**
 * Inits all the REST calls to get property/parcel details
 * @param {string} property property or parcel name from the suggestions autocomplete
 * @example pdInit('7+SCOTT+STREET+NORTHCOTE+3070');
 */
function pdInit(property, pfi, magicKey) {

    // Set search type and PFI REST
    setSearchType(property);
    var REST_URL = (magicKey != null) ? key_REST : pfi_REST;
    var parameter = (magicKey != null) ? magicKey : pfi;

    // Get PFI
    getData(REST_URL, parameter).then((jsonData) => {
        if (jsonData.pfi || provided_pfi) {
            pfi = provided_pfi ? provided_pfi : jsonData.pfi;

            // Get address data
            getData(get_address_REST, pfi).then((jsonData) => {

                address = jsonData.address;
                var geometry = getGeometry(search_type, jsonData);
                var geomType = jsonData.geometryType;
                var projection = jsonData.spatialReference;
                var lga = getLGACode(search_type, jsonData);

                // Print output
                checkRelated(pfi);
                paintOutput(pfi, address);
                getGeoPlexInformation(pfi);
                getLegislative(geometry, geomType, projection);
                getUtilites(geometry, geomType, projection);
                getLGA(lga);
            });
        }
    });

};

/**
 * Get related property Address for 1:1 scenario in parcel search
 */
function getRelatedPropertyAddress() {
    var propertyPFI = related.properties.list[0] !== undefined ? related.properties.list[0] : false;
    if (propertyPFI) {
        getData(address_REST, propertyPFI).then((jsonData) => {
            $('.property-dashboard__item-answer[rel="prop_address"]').removeClass('property-dashboard__item-answer--empty');
            $('.property-dashboard__item-answer[rel="prop_address"]').text(jsonData.address.EZI_ADDRESS)
        });
    } else {
        $('#prop_address').remove();
        $('.property-dashboard__item-answer[rel="prop_address"]').remove();
    }
}

/**
 * Fills in the "PARCEL DETAILS" section
 * @param {number} pfi PFI number por parcel or property
 */
function getParcelData(pfi) {
    getData(parcel_REST, pfi).then((jsonData) => {
        var data = jsonData.features[0].attributes;

        // Lot
        if (data.PARCEL_LOT_NUMBER !== null && data.PARCEL_PLAN_NUMBER !== null) {
            let lot = data.PARCEL_LOT_NUMBER ? `Lot ${data.PARCEL_LOT_NUMBER}` : '';
            let plan = data.PARCEL_PLAN_NUMBER ? data.PARCEL_PLAN_NUMBER : '';
            let lotplan = `${lot} ${plan}`;
            $('.property-dashboard__item-answer[rel="lotplan"]').removeClass('property-dashboard__item-answer--empty');
            $('.property-dashboard__item-answer[rel="lotplan"]').text(lotplan);
        } else {
            $('.property-dashboard__item-answer[rel="lotplan"]').remove();
            $('#lotplan').remove();
        }

        // SPI
        var spi = data.PARCEL_SPI;
        $('.property-dashboard__item-answer[rel="spi"]').removeClass('property-dashboard__item-answer--empty');
        $('.property-dashboard__item-answer[rel="spi"]').text(spi);

        // Property address
        if (search_type == 'property') {
            $('#prop_address').remove();
            $('.property-dashboard__item-answer[rel="prop_address"]').remove();
        } else {
            ;
            getRelatedPropertyAddress();
        }

    });
}

/**
 * Splits a given array to array of n arrays depending on the maxsize
 * @prop {Array} array input array
 * @prop {number} maxsize maximum size of each partial array
 * @return {Array} Array of arrays
 * @example splitArray([1,2,3,4], 2);
 */
function splitArray(array, maxsize) {
    let arraySize = array.length;
    let resultArray = [];

    if (arraySize <= maxsize || maxsize < 1) {
        return [array]
    } else {
        let newArray = [];
        let counter = 0;
        array.forEach(function (item, i) {
            if (counter === maxsize) {
                counter = 0;
                resultArray.push(newArray.slice());
                newArray = [];
            }
            if (counter <= maxsize) {
                counter += 1;
                newArray.push(item);
                if (i + 1 === arraySize) {
                    resultArray.push(newArray.slice());
                }
            }
        });
        return resultArray;
    }
}

/**
 * Filters out related parcels or properties and adds them to the global "related" object
 * @param {json} relatedItems response from the related items REST
 */
function filterOutRelated(relatedItems) {
    relatedItems.map(function (item, i) {
        var parcel_PFI = parseInt(item.attributes.PARCEL_PFI, 10),
            property_PFI = parseInt(item.attributes.PROPERTY_PFI, 10);

        if (related.parcels.list.indexOf(parcel_PFI) === -1) {
            related.parcels.list.push(parcel_PFI);
            related.parcels.count += 1;
        }
        if (related.properties.list.indexOf(property_PFI) === -1) {
            related.properties.list.push(property_PFI);
            related.properties.count += 1;
        }
    });
}


/**
 * Single request to get related properties or parcels in case there is a need for multiple request due to too long GET
 * @param {string} restURL REST usrl
 * @param {Array} pfi PFI number por parcel or property
 * @param {Array} requestCounter Array used to determine when to resolve passed Promise
 * @param {Promise.resolve} resolve Promise resolve function
 */
function singleRelatedRequest(restURL, pfi, requestCounter, resolve) {
    getData(restURL, pfi.join(',')).then(function (data) {
        filterOutRelated(data.features);

        requestCounter.pop();
        if (requestCounter.length === 0) {
            resolve();
        }
    });
}

/**
 * Get related properties or parcels
 * @param {*} step Define step of relation you are requestig - those need to be done in sequence
 * @param {*} maxRequestedPFI Max number or PFIs to pass to a single request
 */
function getRelated(step, maxRequestedPFI) {

    return new Promise(function (resolve, reject) {

        let relatedStep = related.steps['step' + step],
            restURL;

        if (step === 2) {
            relatedStep.items = (search_type == 'property') ? related.parcels.list.slice() : related.properties.list.slice();
            restURL = (search_type == 'property') ? related_parcel_REST : related_property_REST;
        } else if (step === 3) {
            relatedStep.items = (search_type == 'property') ? related.properties.list.slice() : related.parcels.list.slice();
            restURL = (search_type == 'property') ? related_property_REST : related_parcel_REST;
        }

        let splitItems = splitArray(relatedStep.items, maxRequestedPFI);
        relatedStep.requests = splitItems.map((item, i) => {
            return i;
        });
        splitItems.map((pfi) => {
            singleRelatedRequest(restURL, pfi, relatedStep.requests, resolve);
        });

    });
}

/**
 * Handle non 1:1 relations
 */
function nonOneToOneRelation(showPropCount, showParcCount) {

    // Remove unwanter fields
    $('#lotplan').remove();
    $('.property-dashboard__item-answer[rel="lotplan"]').remove();
    $('#spi').remove();
    $('.property-dashboard__item-answer[rel="spi"]').remove();
    $('#prop_address').remove();
    $('.property-dashboard__item-answer[rel="prop_address"]').remove();

    // Fill additional info
    var has_count = (search_type == 'property') ? related.parcels.count : related.properties.count;
    var has_type = (search_type == 'property') ? (has_count === 1 ? 'parcel' : 'parcels') : (has_count === 1 ? 'property' : 'properties');
    var is_count = (search_type == 'property') ? related.properties.count : related.parcels.count;
    var is_type = (search_type == 'property') ? (is_count === 1 ? 'property' : 'properties') : (is_count === 1 ? 'parcel' : 'parcels');
    var type_name = (search_type == 'property') ? 'property' : 'parcel';
    // var showPropCount = related.properties.list.length > 0 ? true : false;
    // var showParcCount = related.parcels.list.length > 0 ? true : false;
    var additionalInfoWrapper = $('.property-dashboard__related-count');
    var additionalInfoText;

    if (showPropCount && !showParcCount) {
        additionalInfoText = `<p>This ${type_name} is 1 of ${is_count} related ${is_type}</p>`;
    } else if (!showPropCount && showParcCount) {
        additionalInfoText = `<p>This ${type_name} has ${has_count} related ${has_type}</p>`;
    } else if (showPropCount && showParcCount) {
        additionalInfoText = `<p>This ${type_name} has ${has_count} related ${has_type}</p><p>and is 1 of ${is_count} related ${is_type}</p>`;
    }

    additionalInfoWrapper.html(additionalInfoText);

    // Show additional info
    $('.property-dashboard__related').show();

    // Bind drawer button
    $('.property-dashboard__related_details').on('click', function () {
        contentDrawer.showDrawer();
    });

}


/**
 * Single request to get related properties or parcels adress in case there is a need for multiple request due to too long GET
 * @param {string} restURL REST usrl
 * @param {Array} pfi PFI number por parcel or property
 * @param {Array} requestCounter Array used to determine when to resolve passed Promise
 * @param {Array} target array to append results to
 * @param {Promise.resolve} resolve Promise resolve function
 * @param {string} type request type - "property" or "parcel"
 */
function singleRelatedAddressesRequest(restURL, pfi, requestCounter, target, resolve, type) {
    getData(restURL, pfi.join(','))
        .then(function (data) {
            return data
        })
        .then(function (data) {

            // Parcel
            if (type === 'parcel') {
                data.map((item) => {

                    // Commented out due to VPP-3751
                    // // parcel has no plan or lot - remove
                    // if (item.plan === null || item.lot === null || item.lot === '') {
                    //     let pfi = parseInt(item.pfi, 10);
                    //     let pfiIndex = related.parcels.list.indexOf(pfi);

                    //     related.parcels.list.splice(pfiIndex, 1);
                    //     related.parcels.count = related.parcels.count - 1;

                    // // ... else is good
                    // } else {
                    //     target.push(item);
                    // }

                    target.push(item);

                });

                // Property
            } else {
                data.map((item) => {
                    target.push(item);
                });
            }

            // Handle promise resolve - resolve if this was the last request response
            requestCounter.pop();
            if (requestCounter.length === 0) {
                resolve();
            }
        });
}

/**
 * Get related addresses
 */
function getRelatedAddresses() {

    return new Promise(function (resolve, reject) {

        // Slice related elements to arrays to request max X related per request
        let relatedProperties = related.properties.list.length ? splitArray(related.properties.list.slice(), 80) : [];
        let relatedParcels = related.parcels.list.length ? splitArray(related.parcels.list.slice(), 80) : [];

        // Count all requests to be made - one entry in array per request
        for (let i = 0; i < relatedProperties.length + relatedParcels.length; i += 1) {
            related.address.request.push(i);
        }

        // Request properties address
        if (relatedProperties.length) {
            relatedProperties.map((pfi) => {
                singleRelatedAddressesRequest(multiple_address_REST, pfi, related.address.request, related.address.properties, resolve, 'property');
            });
        }

        // Request parcel address
        if (relatedParcels.length) {
            relatedParcels.map((pfi) => {
                singleRelatedAddressesRequest(multiple_parcel_REST, pfi, related.address.request, related.address.parcels, resolve, 'parcel');
            });
        }

    });

}

/**
 * Print related properties/parcels Drawer
 */
function printRelatedDrawer() {
    let headingTitle = (search_type == 'property') ? "Property information" : "Parcel information";
    let headingAddress = (search_type == 'property') ? `${address.NUM_ROAD_ADDRESS.toLowerCase()}<br />${address.LOCALITY_NAME} ${address.POSTCODE}` : property;
    let linkURL = window.location.href.split('?')[0];

    // Wrapper and heading
    $('#content-drawer__content-wrapper').html(`<div class="content-drawer__content property-dashboard__drawer"></div>`);
    $('.property-dashboard__drawer').append(`<h3 class="property-dashboard__drawer-heading">${headingTitle}</h3>`);
    $('.property-dashboard__drawer').append(`<h4 class="property-dashboard__drawer-address">${headingAddress}</h4>`);

    // Related properties
    let relatedProperties = $('<div class="property-dashboard__drawer-related"></div>');
    relatedProperties.append('<h5 class="property-dashboard__drawer-related-heading">Related properties</h5>');
    relatedProperties.append('<ul class="property-dashboard__drawer-related-list"></ul>');
    let relatedPropertiesList = relatedProperties.find('.property-dashboard__drawer-related-list');
    related.address.properties.map((item, i) => {
        let displayAddress = item.address;
        let linkAddress = item.address.replace(/\s/g, '+');
        let pfi = item.pfi;
        relatedPropertiesList.append(`<li class="property-dashboard__drawer-related-list-item">${displayAddress} <a class="property-dashboard__drawer-link" href="${linkURL}?property=${linkAddress}&pfi=${pfi}">View Property</a></li>`);
    });

    // Related parcels
    let relatedParcels = $('<div class="property-dashboard__drawer-related"></div>');
    relatedParcels.append('<h5 class="property-dashboard__drawer-related-heading">Related parcels</h5>');
    relatedParcels.append('<ul class="property-dashboard__drawer-related-list"></ul>');
    let relatedParcelsList = relatedParcels.find('.property-dashboard__drawer-related-list');
    related.address.parcels.map((item) => {
        let pfi = item.pfi;
        relatedParcelsList.append(`<li class="property-dashboard__drawer-related-list-item">${item.lot !== null ? item.lot : ''} ${item.plan !== null ? item.plan : ''} (SPI: ${item.spi}) <a class="property-dashboard__drawer-link" href="${linkURL}?property=${item.spi}&pfi=${pfi}">View Parcel</a></li>`);
    });

    // Append to Drawer
    if (search_type == 'property') {
        if (related.address.properties.length) {
            $('.property-dashboard__drawer').append(relatedProperties);
        }
        if (related.address.parcels.length) {
            $('.property-dashboard__drawer').append(relatedParcels);
        }
    } else {
        if (related.address.parcels.length) {
            $('.property-dashboard__drawer').append(relatedParcels);
        }
        if (related.address.properties.length) {
            $('.property-dashboard__drawer').append(relatedProperties);
        }
    }

}

/**
 * Remove current pfi from related
 */
function removeCurrentFromRelated() {
    let targetArray = (search_type === 'property') ? related.properties.list : related.parcels.list;
    let targetArrayCount = (search_type === 'property') ? related.properties : related.parcels;
    let currentIndex = targetArray.indexOf(pfi);

    targetArray.splice(currentIndex, 1);
    targetArrayCount.count = targetArray.length;

}

/**
 * Print Vic Plan link using property PFI only - VPP-3830
 */
function printVicPlanLink(pfi) {

    let vicPlanPfi = (search_type === 'property') ? pfi : related.properties.list[0];
    if (undefined !== vicPlanPfi) {
        let vicPlanUrl = $('.top-bar__button--map-pd').data('href') + vicPlanPfi;
        $('.top-bar__button--map-pd').attr('href', vicPlanUrl);
        $('.top-bar__button--map-pd').removeClass('top-bar__button--disabled');
    } else {
        $('.top-bar__button--map-pd').remove();
    }
}

/**
 * Print Property PDF using property PFI only - DLSR-30
 */
function printVicPropertyPDF(pfi) {

    let vicPlanPfi = (search_type === 'property') ? pfi : related.properties.list[0];
    if (undefined !== vicPlanPfi) {
        let vicPlanPDFUrl = $('.top-bar__button--pdf-property').data('href') + vicPlanPfi;
        $('.top-bar__button--pdf-property').attr('href', vicPlanPDFUrl);
        $('.top-bar__button--pdf-property').removeClass('top-bar__button--disabled');
    } else {
        $('.top-bar__button--pdf-property').remove();
    }
}

/**
 * Print Map Share Vic link using property PFI only - DLSR-16
 */
function printMapShareVic(pfi) {

    const vicPlanPfi = (search_type === 'property') ? pfi : related.properties.list[0];
    if (undefined !== vicPlanPfi) {
        let vicPlanUrl = $('.top-bar__button--map-ms').data('href') + vicPlanPfi;
        $('.top-bar__button--map-ms').attr('href', vicPlanUrl);
        $('.top-bar__button--map-ms').removeClass('top-bar__button--disabled');
    } else {
        $('.top-bar__button--map-ms').remove();
    }
}

/**
 * Print related property/parcel data
 */
function printRelatedData(pfi) {

    // Print Vic Plan link using property PFI only - VPP-3830
    printVicPlanLink(pfi);

    // Print Map Share Vic link using property PFI only - DLSR-16
    printMapShareVic(pfi);

    //Print Property PDF using property PFI only - DLSR-30
    printVicPropertyPDF(pfi);

    // 1:1
    if (related.properties.count === 1 && related.parcels.count === 1) {
        console.log('Relation scenario: 1(prop) : 1(parc)');
        getParcelData(related.parcels.list[0]);
    }

    // Not 1:1
    else {

        // Remove current pfi from related
        // removeCurrentFromRelated(); // removed as requested in VPP-3755

        /*
         * VPP-3741 code below
         */
        let showPropCount;
        let showParcCount;

        // Property
        if (search_type == 'property') {

            // 1. Property (with NO Related Properties) plus Related Parcels
            if (related.parcels.count > 0 && related.properties.count === 0) {
                console.log('[Prop] Relation scenario: 0(prop) : >0(parc)');

                // Set flags
                showPropCount = true;
                showParcCount = false;

                // 2. Property (with Related Properties) plus ONLY 1 Related Parcel
            } else if (related.parcels.count === 1 && related.properties.count > 0) {
                console.log('[Prop] Relation scenario: >0(prop) : 1(parc)');

                // Clear related parcels
                // related.parcels.list = [];
                // related.parcels.count = 0;

                // Set flags
                showPropCount = true;
                showParcCount = false;

                // 3. Propert with one related properties plus MORE then 1 Related Parcels
            } else if (related.parcels.count > 1 && related.properties.count === 1) {
                console.log('[Prop] Relation scenario: 1(prop) : >1(parc)');

                // Remove current from related
                removeCurrentFromRelated();

                // Set flags
                showPropCount = related.properties.count > 0 ? true : false;
                showParcCount = true;

                // 4. Property (with Related Properties) plus MORE than 1 Related Parcels
            } else if (related.parcels.count > 1 && related.properties.count > 0) {
                console.log('[Prop] Relation scenario: >0(prop) : >1(parc)');

                // Set flags
                showPropCount = true;
                showParcCount = true;

            }

            // Parcel
        } else {

            // 1. Parcel (with NO Related Parcels) plus Related Properties
            if (related.parcels.count === 0 && related.properties.count > 0) {
                console.log('[Parc] Relation scenario: >0(prop) : 0(parc)');

                // Set flags
                showPropCount = false;
                showParcCount = true;

                // 2. Parcel (with Related Parcels) plus ONLY 1 Related Property
            } else if (related.parcels.count > 0 && related.properties.count === 1) {
                console.log('[Parc] Relation scenario: 1(prop) : >0(parc)');

                // Clear related parcels - commented out due to VPP-3780
                // related.properties.list = [];
                // related.properties.count = 0;

                // Set flags
                showPropCount = true;
                showParcCount = false;

                // 3. Parcel with one related parcel plus MORE then 1 Related Properties
            } else if (related.parcels.count === 1 && related.properties.count > 1) {
                console.log('[Parc] Relation scenario: >1(prop) : 1(parc)');

                // Remove current from related
                removeCurrentFromRelated();

                // Set flags
                showPropCount = related.parcels.count > 0 ? true : false;
                showParcCount = true;

                // 4. Parcel (with Related Parcels) plus MORE than 1 Related Properties
            } else if (related.parcels.count > 0 && related.properties.count > 1) {
                console.log('[Parc] Relation scenario: >1(prop) : >0(parc)');

                // Set flags
                showPropCount = true;
                showParcCount = true;

            }

        }

        /*
         * VPP-3741 code above
         */

        // Get all adresses and print them
        getRelatedAddresses().then(() => {
            printRelatedDrawer();
            nonOneToOneRelation(showPropCount, showParcCount);
        });

    }

}

/**
 * Look for related properties/parcels
 * @param {number} pfi PFI number por parcel or property
 */
function checkRelated(pfi) {
    var related_REST = (search_type == 'property') ? related_property_REST : related_parcel_REST;

    getData(related_REST, pfi).then((jsonData) => {

        // There is no error in related response
        if (!jsonData.hasOwnProperty('error')) {
            filterOutRelated(jsonData.features);
            getRelated(2, 80).then(() => {
                getRelated(3, 80).then(() => {
                    printRelatedData(pfi);
                });
            });

            // There is an error in related response ...
        } else {
            console.log('Error in: ', related_REST, ' response.');

            // ... and this is a parcel so just get parcel data from current pfi
            if (search_type === 'parcel') {
                getParcelData(pfi);

                // ... and this is a property so hide parcel details as there is no way to tell what parcel is this property on
            } else {
                $('.property-dashboard__item-answer[rel="lotplan"]').remove();
                $('#lotplan').remove();
                $('.property-dashboard__item-answer[rel="spi"]').remove();
                $('#spi').remove();
                $('.property-dashboard__item-answer[rel="prop_address"]').remove();
                $('#prop_address').remove();
            }
        }
    });

}

/**
 * Print "STATE ELECTORATES" section
 * @param {json} geometry geometry data
 * @param {string} geomType geometry type
 * @param {json} projection projection data
 */
function getLegislative(geometry, geomType, projection) {

    $.post(legislative_REST, {
        geometry: encodeURI(JSON.stringify(geometry)),
        geometrytype: geomType,
        inSR: projection.wkid
    }, function () {}, "json").done(function (data) {
        $('#assembly').text(data[0].DISTRICT);
        $('#council').text(data[0].REGION);
    });

}

/**
 * Remove Code from label - VPP-3717
 * @param {string} label
 * @param {string} code
 * @returns {string} String with code removed the label
 */
function printZoneAndOverlay(label, code) {
    let removeCode = '^' + code + ' -|\\(' + code + '\\)';
    let removeCodeRegExp = new RegExp(removeCode, 'g');
    return label.replace(removeCodeRegExp, '') + ' (' + code + ')';
}

/**
 * Remove duplicate entries in "Zones and Overlays"
 */
function sanitizeZonesAndOverlays() {
    $('.property-dashboard__item-answer--list').each((i, list) => {
        let entries = [];
        $(list).find('li').each((n, listItem) => {
            let textValue = $(listItem).text().replace(/\s|\-/g, '').toLowerCase();
            if (entries.indexOf(textValue) === -1) {
                entries.push(textValue);
            } else {
                $(listItem).remove();
            }
        });
    });
}

/**
 * Print "ZONES AND OVERLAYS" section
 * @param {number} pfi PFI number por parcel or property
 */
function getGeoPlexInformation(pfi) {

    getData(geoplex_REST, pfi + "&inputSearchType=" + search_type).then((jsonData) => {

        $('#zones-list .property-dashboard__item-answer--empty').remove();
        $('#overlays-list .property-dashboard__item-answer--empty').remove();

        // Result error - remove section
        if (jsonData.hasOwnProperty('error')) {
            $('.x1tox3').remove();
            $('#cpn').remove();
            $('.property-dashboard__item-answer[rel="cpn"]').remove();

            // No errors - print results
        } else {
            for (var i = jsonData.length - 1; i >= 0; i--) {
                var element = jsonData[i];
                switch (element.paramName) {
                    case 'zones':
                        if (element.value.features.length) {
                            element.value.features.forEach(function (item, index) {
                                $('#zones-list').append('<li>' + printZoneAndOverlay(item.attributes.ZONE_CODE_GROUP_LABEL, item.attributes.ZONE_CODE_GROUP) + '</li>')
                                $('#zones-list').append('<li>' + printZoneAndOverlay(item.attributes.ZONE_DESCRIPTION, item.attributes.ZONE_CODE) + '</li>')
                            });
                        } else {
                            $('#zones').remove();
                            $('#zones-list').remove();
                        }
                        break;
                    case 'overlays':
                        if (element.value.features.length) {
                            element.value.features.forEach(function (item, index) {
                                $('#overlays-list').append('<li>' + printZoneAndOverlay(item.attributes.ZONE_CODE_GROUP_LABEL, item.attributes.ZONE_CODE_GROUP) + '</li>');
                                $('#overlays-list').append('<li>' + printZoneAndOverlay(item.attributes.ZONE_DESCRIPTION, item.attributes.ZONE_CODE) + '</li>');
                            });
                        } else {
                            $('#overlays').remove();
                            $('#overlays-list').remove();
                        }
                        break;
                    case 'searchFeature':
                        element.value.features.forEach(function (item, index) {
                            var cpn = item.attributes.PROP_PROPNUM;

                            if (cpn) {
                                $('.property-dashboard__item-answer[rel="cpn"]').removeClass('property-dashboard__item-answer--empty');
                                $('.property-dashboard__item-answer[rel="cpn"]').text(cpn);
                            } else {
                                $('#cpn').remove();
                                $('.property-dashboard__item-answer[rel="cpn"]').remove();
                            }
                        });
                        break;
                }
            }

            // No zones or overlays
            if (!$('#zones').length && !$('#overlays').length) {
                $('.x1tox3').remove();

                // Remove duplicate entries
            } else {
                sanitizeZonesAndOverlays();
            }
        }

    });
}

/**
 * Print heading details/address and heading buttons
 * @param {number} pfi PFI number por parcel or property
 * @param {json} address address JSON
 */
function paintOutput(pfi, address) {

    $('.search-header__title').removeClass('search-header__title--empty');
    $('.search-header__sec-desp').removeClass('search-header__sec-desp--empty');

    // update page title
    if (search_type === 'property') {
        $('.search-header__title').text(address.NUM_ROAD_ADDRESS.toLowerCase());
        //$('.search-header__sec-desp').html(address.LOCALITY_NAME + ' <span>' + address.POSTCODE + '</span>');
        $('.search-header__sec-desp').html(address.LOCALITY_NAME + ' ' + address.POSTCODE);
    } else if (retain_search_type === 'crown') { 
        $('.search-header__title').text(property.replace(/\+/g, ' '));
        $('.search-header__sec-desp').hide();
    } else {
        $('.search-header__title').text(property);
        $('.search-header__sec-desp').hide();
    }

    // PDF button
    let pdfButtonProp = 'http://services.land.vic.gov.au/landchannel/content/vicplanpdfreport?propertypfi=' + pfi + '&reportType=vicplan&source=planportal';
    let pdfButtonParc = 'http://services.land.vic.gov.au/landchannel/content/vicplanpdfreport?parcelpfi=' + pfi + '&reportType=vicplan&source=planportal';
    let pdfButton = search_type === 'property' ? pdfButtonProp : pdfButtonParc;
    $('.top-bar__button--pdf-download').attr('href', pdfButton);
    $('.top-bar__button--pdf-download').removeClass('top-bar__button--disabled');

    // Print map
    $('.property-dashboard__item--map').removeClass('property-dashboard__item--map-empty');
    $('#map').html('<iframe width="100%" height="100%" src="' + map_endpoint + '?scenario=' + search_type + '&' + search_type + '_pfi=' + pfi + '"/>');

    // Property or Parcel text
    $('.search_type').text(search_type);

}

/**
 * Print "UTILITES" section
 * @param {json} geometry geometry data
 * @param {string} geomType geometry type
 * @param {json} projection projection data
 */
function getUtilites(geometry, geomType, projection) {
    $.post(utilites_REST, {
        geometry: encodeURI(JSON.stringify(geometry)),
        geometrytype: geomType,
        inSR: projection.wkid
    }, function () {}, "json").done(function (data) {

        // Rural Water Corporation
        if (data[0].features.length === 0) {
            $('#rural-water-corp').prev('.property-dashboard__item-question').remove();
            $('#rural-water-corp').remove();
        } else {
            $('#rural-water-corp').text(data[0].features[0].attributes.WATERCORP_NAME);
        }

        // Urban Water Corporation
        if (data[1].features.length === 0) {
            $('#urban-water-corp').prev('.property-dashboard__item-question').remove();
            $('#urban-water-corp').remove();
        } else {
            $('#urban-water-corp').text(data[1].features[0].attributes.WATERCORP_NAME);
        }

        // Melbourne Water Retailer
        if (data[2].features.length === 0) {
            $('#melb-water-ret').prev('.property-dashboard__item-question').remove();
            $('#melb-water-ret').remove();
        } else {
            $('#melb-water-ret').text(data[2].features[0].attributes.WATERCORP_NAME);
        }

        // Melbourne Water
        if (data[3].features.length === 0) {
            $('#melb-water-corp').text('outside drainage boundary');
        } else {
            let melWater = data[3].features[0].attributes.WATERCORP_NAME === 'Melbourne Water' ? 'inside drainage boundary' : 'outside drainage boundary';
            $('#melb-water-corp').text(melWater);
        }

        // Power Distributor
        if (data[4].features.length === 0) {
            $('#power-dist').prev('.property-dashboard__item-question').remove();
            $('#power-dist').remove();
        } else {
            $('#power-dist').text(data[4].features[0].attributes.ENERGY_DISTRIBUTOR);
        }
    });

}

/**
 * Print LGA related elements
 * @param {number} query LGA code
 */
function getLGA(query) {

    $.getJSON(lga_REST, {
        query
    }).done(function (data) {

        // Parcel Details - LGA
        $('.property-dashboard__item-answer[rel="lgac"]').removeClass('property-dashboard__item-answer--empty');
        $('.property-dashboard__item-answer[rel="lgac"]').text(data.features[0].attributes.LGA_NAME);

        // Know your council
        var lgaName = data.features[0].attributes.LGA_NAME,
            lgaName = lgaName.replace(/\s+/g, '-').toLowerCase(),
            lgaHref = $('#lga').data('href');

        $('#lga').attr('href', lgaHref + lgaName);
        $('#lga').removeClass('property-dashboard__link--empty');

        // VicPlan
        var vicPlanHref = $('#vicplan').data('href');
        $('#vicplan').attr('href', vicPlanHref + lgaName);
        $('#vicplan').removeClass('property-dashboard__link--empty');

        // MapShareVic
        var mapShareVic = $('#mapsharevic').data('href');
        $('#mapsharevic').attr('href', mapShareVic + lgaName);
        $('#mapsharevic').removeClass('property-dashboard__link--empty');

        // Go to X Planning Scheme button
        var goToButton = $('.top-bar__button--go-to');
        var goToButtonHref = goToButton.data('href');
        var lgaNameWrapper = $('.top-bar__button--lga-name');
        var lgaNameDisplay = data.features[0].attributes.LGA_NAME.toLowerCase();
        var lgaNameURL = data.features[0].attributes.LGA_NAME.replace(/\s+/g, '+').toLowerCase();

        goToButton.attr('href', goToButtonHref + lgaNameURL);
        lgaNameWrapper.text(lgaNameDisplay);
        goToButton.removeClass('top-bar__button--disabled');
    });

}