/*global jQuery, accordions*/

var contentDrawer = {};

(function ($) {
    'use strict';

    // Settings
    contentDrawer.settings = {
        URLSuffix:      '?SQ_PAINT_LAYOUT_NAME=drawer&SQ_DESIGN_NAME=blank', // Page content for content drawer
        pushPopSupport: (undefined !== history.pushState && undefined !== window.onpopstate) ? true : false, // pushState support
        xhr:            undefined // Ajax call object
    };

    // Selectors
    contentDrawer.selectors = {
        callLinks:  'a.content-drawer__link', // Trigger for content drawer
        wrapper:    '#content-drawer', // Parent element of drawer items
        content:    '#content-drawer__content-wrapper', // Ajax response will be put here
        closeBtn:   '#content-drawer__close', // Close button, gains focus on drawer init
        loader:     '#content-drawer__loading', // Loader icon
        overlay:    '#overlay',
        bodyActive: 'drawer-opened', // Class to be added on drawer open
        closeWrpr:  '#content-drawer__close-wrapper'
    };

    // Flags
    contentDrawer.flags = {
        inProgress:        false, // call in progress
        opened:            false, // call finished and drawer opened
        currentLoadedHref: undefined, // Last loaded href
        currentURL:        undefined // Current URL
    };

    contentDrawer.init = function () {

        // Quit when no drawer placeholder is in place
        if (!$(contentDrawer.selectors.wrapper).length) {
            return false;
        }

        // Add hidden text to callLinks
        $(contentDrawer.selectors.callLinks).append('<span class="sr-only">Open in slide out</span>');

        $('body')
        // Prevent to focus out of slideout
            .on('focus', 'a', function (e) {
                if (contentDrawer.flags.opened && !$(this).parents(contentDrawer.selectors.wrapper).length) {
                    $(contentDrawer.selectors.closeBtn).focus();
                }
            })
        // Set callLinks items
            .on('click', contentDrawer.selectors.callLinks, function (e) {
                e.preventDefault();

                // Remove hints if present
                $(contentDrawer.selectors.callLinks).removeClass('content-drawer__link--double-clicked');

                if ($(this).attr('data-href')) {
                    contentDrawer.load($(this).attr('data-href'));
                } else {
                    contentDrawer.load($(this).attr('href'));
                }
            })
        // Set close
            .on('click', contentDrawer.selectors.closeBtn, function (e) {
                e.preventDefault();
                $(contentDrawer.selectors.content).scrollTop(0);
                contentDrawer.hideDrawer();
            });

        // Close by ESC key and overlay click
        contentDrawer.additionalClose();

        // History back support
        contentDrawer.historyBackHandler();
        
        contentDrawer.lookForDrawer(window.location.hash);
    };

    // Loader
    contentDrawer.loader = function (showLoader, showOverlay, inProgress, Opened) {

        // Icon toggle
        if (showLoader) {
            $(contentDrawer.selectors.loader).fadeIn('fast'); // Show loader
            $(contentDrawer.selectors.loadingTxt).focus(); // Focus on loader
        } else if (!showLoader && undefined !== showLoader) {
            $(contentDrawer.selectors.loader).fadeOut('fast'); // Hide loader
        }

        // Overlay toggle
        if (showOverlay) {
            $(contentDrawer.selectors.overlay).addClass('overlay--active-drawer'); // Show overlay
        } else if (!showOverlay && undefined !== showOverlay) {
            $(contentDrawer.selectors.overlay).removeClass('overlay--active-drawer'); // Hide overlay
        }

        // Manage inProgress flag
        if (inProgress) {
            contentDrawer.flags.inProgress = true;
        } else if (!inProgress && undefined !== inProgress) {
            contentDrawer.flags.inProgress = false;
        }

        // Manage Opened flag
        if (Opened) {
            contentDrawer.flags.opened = true;
        } else if (!Opened && undefined !== Opened) {
            contentDrawer.flags.opened = false;
        }
    };

    // Load - ajax call
    contentDrawer.load = function (href) {
        var $currCallLink;
        
        if ($(contentDrawer.selectors.callLinks + '[data-href="' + href + '"]').length) {
            $currCallLink = $(contentDrawer.selectors.callLinks + '[data-href="' + href + '"]');
        } else {
            $currCallLink = $(contentDrawer.selectors.callLinks + '[href="' + href + '"]');
        }

        // Abort on #
        if (href === '#') {
            return false;
        }

        if (undefined !== contentDrawer.settings.xhr) {
            contentDrawer.settings.xhr.abort();
        }

        // Show loader
        contentDrawer.loader(true, true, true, false);
        // Make ajax call
        contentDrawer.settings.xhr = $.ajax({
            url:      href + contentDrawer.settings.URLSuffix,
            dataType: 'html'
        }).done(function (data) {

            // Set flag
            contentDrawer.flags.currentLoadedHref = href;
            contentDrawer.flags.currentURL = window.location.href;

            // Call success handler
            contentDrawer.loaded(data, href);
        }).fail(function (textStatus) {
            contentDrawer.loader(false, false, false, false);

            // Do not render content drawer on XHR status code 0 (abort)...
            if (0 !== textStatus.status) {
                // Frontend msg
                contentDrawer.loaded('<div class="content-drawer__content"><h1>A ' + textStatus.status + ' error occured: ' + textStatus.statusText + '</h1>', href);
            } else {
                // ...Give user a 'click once' hint instead
                $currCallLink.addClass('content-drawer__link--double-clicked');
            }
        });
    };

    // Ajax success handler
    contentDrawer.loaded = function (data, href) {

        // Clear content HTML
        $(contentDrawer.selectors.content)
            .html('')
        // Put response into drawer
            .append(data);

        // Init plugin on accordions inside of content drawer
        accordions.init(true);

        // Show content drawer
        contentDrawer.showDrawer(href);
    };

    // Show content drawer
    contentDrawer.showDrawer = function (href) {

        // Prevent body from scrolling
        $('body').addClass(contentDrawer.selectors.bodyActive);

        // Show drawer
        $(contentDrawer.selectors.wrapper).show();

        // Hide loader
        contentDrawer.loader(false, true, true, false);

        // Slide out drawer
        $(contentDrawer.selectors.wrapper).animate({
            right: '0'
        }, 500, function () {

            // Change focus
            $(contentDrawer.selectors.closeBtn).focus();

            // Handle URL
            if(href) {
                contentDrawer.URLHandler(true, href);
            }

            $(contentDrawer.selectors.content).scroll(function () {
                if ($(this).scrollTop()) {
                    $(contentDrawer.selectors.closeWrpr).addClass('content-drawer__close-wrapper--scrolled');
                } else {
                    $(contentDrawer.selectors.closeWrpr).removeClass('content-drawer__close-wrapper--scrolled');
                }
            });

            // Set as finished
            contentDrawer.loader(false, true, false, true);
        });
    };

    // Close content drawer
    contentDrawer.hideDrawer = function () {

        if (contentDrawer.flags.opened) {

            // Set flags
            contentDrawer.loader(false, true, true, false);

            // Hide drawer
            $(contentDrawer.selectors.wrapper).animate({
                right: -$(contentDrawer.selectors.wrapper).width()
            }, 500, function () {

                // Remove class from body element
                $('body').removeClass(contentDrawer.selectors.bodyActive);

                $(contentDrawer.selectors.wrapper).hide('fast', function () {

                    // Handle URL
                    contentDrawer.URLHandler(false);

                    // Set as finished
                    contentDrawer.loader(false, false, false, false);
                });
            });
        } else {

            // Cancel xhr
            if (undefined !== contentDrawer.settings.xhr) {
                contentDrawer.settings.xhr.abort();
            }

            // Set flags
            contentDrawer.loader(false, false, false, false);
        }
    };

    // Close by ESC key and overlay click
    contentDrawer.additionalClose = function () {

        // ESC
        $(document).keyup(function (e) {
            if (e.keyCode === 27 && (contentDrawer.flags.opened || contentDrawer.flags.inProgress)) {
                $(contentDrawer.selectors.content).scrollTop(0);
                contentDrawer.hideDrawer();
            }
        });

        // Overlay click
        $('#overlay').on('click', function () {
            if (contentDrawer.flags.opened || contentDrawer.flags.inProgress) {
                $(contentDrawer.selectors.content).scrollTop(0);
                contentDrawer.hideDrawer();
            }
        });
    };

    // URL hash handler
    contentDrawer.URLHandler = function (setURL, href) {
        var currTitle;

        if (setURL) {
            // Find element that called drawer, get its title
            if ($(contentDrawer.selectors.callLinks + '[data-href="' + href + '"]').length) {
                currTitle = ($(contentDrawer.selectors.callLinks + '[data-href="' + href + '"]').attr('title')).replace(/ /g, '_');
            } else {
                currTitle = ($(contentDrawer.selectors.callLinks + '[href="' + href + '"]').attr('title')).replace(/ /g, '_');
            }

            // pushState support
            if (contentDrawer.settings.pushPopSupport) {
                history.pushState(null, null, '#' + currTitle);
            } else { // pushState fallback
                window.location.hash = currTitle;
            }
        } else {
            if (contentDrawer.settings.pushPopSupport) {
                history.replaceState('', document.title, window.location.pathname + window.location.search);
            } else {
                window.location.hash = '';
            }
        }
    };

    // Handle URL, popstate
    contentDrawer.historyBackHandler = function () {

        // popState support
        if (contentDrawer.settings.pushPopSupport) {
            window.onpopstate = function () {
                if (contentDrawer.flags.opened) {
                    contentDrawer.hideDrawer();
                } else {
                    contentDrawer.lookForDrawer(window.location.hash);
                }
            };
        } else { // popState fallback
            window.onhashchange = function () {
                if (contentDrawer.flags.opened) {
                    contentDrawer.hideDrawer();
                } else {
                    contentDrawer.lookforDrawer(window.location.hash);
                }
            };
        }
    };

    // Open drawer on page load
    contentDrawer.lookForDrawer = function (hash) {
        var trgrElem;

        if (hash.length) {
            // Check if link actually exist on the page
            trgrElem = $(contentDrawer.selectors.callLinks + '[title="' + hash.replace(/#/g, '').replace(/_/g, ' ') + '"]');

            // Return false when no link with current hash is found
            if (hash === '#navigation' || !trgrElem.length) {
                return false;
            } else {
                // Open drawer
                if (trgrElem.attr('data-href')) {
                    contentDrawer.load(trgrElem.attr('data-href'));
                } else {
                    contentDrawer.load(trgrElem.attr('href'));
                }
            }

        }
    };



    const externalLinkIdentifier = {
        settings: {
            scope:    'main-content-wrapper',
            selector: 'a',
            drawer:   'content-drawer__link',
            exclude:  [
                'section',
                'nav',
                'img',
                'form',
                'aside',
                '.accordion__link',
                '.block-quote',
                '.btn',
                '.btn__link',
                '.categories__link',
                '.form-accordion__btn',
                '.tabs',
                '[href^="mailto:"]',
                '[href^="tel:"]',
                '[href^="#"]'
            ]
        },

        init: () => {
            const _this = externalLinkIdentifier;

            if (document.querySelector(`.${_this.settings.scope}`) && document.querySelector(`.${_this.settings.scope}`).querySelectorAll(`${_this.settings.selector}`))
            _this.addIdentifier(_this.links(_this.allLinks(),_this.excludeLinks()));
        },
        allLinks: () => {
            const _this  = externalLinkIdentifier,
                allLinks = document.querySelector(`.${_this.settings.scope}`).querySelectorAll(`${_this.settings.selector}`);
            return allLinks;
        },
        excludeLinks: () => {
            const _this  = externalLinkIdentifier,
                    scope = document.querySelector(`.${_this.settings.scope}`);
            let excludelinks = [];

            // Excluded selectors
            for (const exclude of _this.settings.exclude) {
                for (const element of scope.querySelectorAll(`${exclude}`)) {
                    if (element.querySelectorAll(`${_this.settings.selector}`).length) {
                        excludelinks.push(...[...element.querySelectorAll(`${_this.settings.selector}`)]);
                    } else {
                        excludelinks.push(element);
                    }
                }
            }

            return excludelinks;
        },
        links: (allLinks, excludeLinks) => {
            const _this  = externalLinkIdentifier;
            let links = [];

            for (const link of Array.from(allLinks)) {
                if (Array.from(excludeLinks).indexOf(link) === -1) {
                    links.push(link);
                }

            }

            return links;
        },
        addIdentifier: (links) => {
            const _this = externalLinkIdentifier;

            for (const link of Array.from(links)) {
                if (link.getAttribute('data-href')) {
                    if ((link.getAttribute('data-href').indexOf(location.hostname) === -1)
                    && (link.getAttribute('class') !== `${_this.settings.drawer}`)
                    && (link.textContent.trim().length)) {
                        link.classList.add('external-link');
                        link.setAttribute('target', '_blank');

                    } else if (link.getAttribute('class') !== `${_this.settings.drawer}`) {
                        link.classList.add('internal-link');
                    }
                }
            }
        }
    }

        // Document ready
    $(document).ready(function () {

        // Run init
        contentDrawer.init();
        externalLinkIdentifier.init();

    });

}(jQuery));