//sort project dropdown on mobile view
var $sortOptions = $(".project-section__sort"),
$sortOption  = $(".sort-project-section__list li a.project-section__link"),
$optionSeled = $(".sort-project-section--selected"),
$contentTab  = $(".project-section__target");

var selectedSortOption = "project-section--selected",
optionListExpanded = "expanded";

$sortOptions.on("click", function(){
    event.stopPropagation();
    $(this).toggleClass(optionListExpanded);
});

$sortOption.on("click", function() {
    $sortOption.each(function() {
        if($(this).hasClass(selectedSortOption)) {
            $(this).removeClass(selectedSortOption);
            return false;
        }
    });
    
    $(this).addClass(selectedSortOption);
        $optionSeled.text($(this).text());
    });

    $(window).click(function() {
        if($sortOptions.hasClass(optionListExpanded)) {
        $sortOptions.removeClass(optionListExpanded);
    }
});

// shows content
function Tabs(selector, options) {
    if (!(this instanceof Tabs)) return new Tabs(selector, options);

    selector = selector + '' === selector ? selector : 'a[href^="#section"]';
    options = options && options.hasOwnProperty ? options : {};

    this.options = {};

    var item;
    for (item in this.defaults) this.options[item] = (
        options[item] === undefined ? this.defaults[item] : options[item]
    );

    var links = document.querySelectorAll(selector);
    for (var i = 0; links[i]; i++) {
        this[this.push(links[i]) - 1].addEventListener('click', this);
    }

    if (this[this.options.activeTab || 0]) {
        this[this.options.activeTab || 0].click();
    }
}
Tabs.prototype = [];
Tabs.prototype.defaults = {
    contentActive: 'active',
    linkActive: 'active',
    allowClose: false,
    activeTab: 0
};
Tabs.prototype.options = {};
Tabs.prototype.latest = null;
Tabs.prototype.handleEvent = function(event, content) {
    event.preventDefault();

    if (this.latest) {
        this.latest.classList.remove(this.options.linkActive);
        if (content = document.querySelector(this.latest.hash)) {
            content.classList.remove(this.options.contentActive);
        }
    }

    if (this.latest === event.target && this.options.allowClose) {
        this.latest = null;
        return;
    }

    this.latest = event.target;
    this.latest.classList.add(this.options.linkActive);
    if (content = document.querySelector(this.latest.hash)) {
        $contentTab.removeClass(this.options.contentActive);
        content.classList.add(this.options.contentActive);
    }
};

new Tabs('.project-section__link', {
    contentActive: 'active',
    allowClose: false,
    activeTab: 0
});

$sortOption.on("click", function() {
    if(history.pushState) {
        history.pushState(null, null, $(this).attr("href"));
    }
    else {
        location.hash = $(this).attr("href");
    }
});

//open tab with hash passed
if(window.location.hash.length >= 0 && $(".project-sections").length > 0){
    var id = (window.location.hash.length) ? window.location.hash : '#overview';
    if($(id).length == 1) {
        $(".project-section__link.project-section--selected").removeClass("project-section--selected active");
        $contentTab.removeClass("active");
        $("a[href='"+id+"']").addClass("project-section--selected active");
        $(id).addClass("active");
        $(".project-section__sort").removeClass("expanded");
        $(".sort-project-section--selected").text($("a[href='"+id+"']").text());
    }
}
