(function ($) {
    "use strict";
    //text variable
    var backDefText  = "Back",
        backMenuDef  = "MENU";

    //class names
    var active          = "header-active-class",
        current         = "header-current-class",
        menuL1ClassName = "megamenu__list",
        menuL2ClassName = "megamenu__list-l2",
        menuL3ClassName = "megamenu__list-l3",
        backBtnArrowCN  = "megemenu__back-arrow",
        currentSession  = "landing-page",
        submenuClass    = "has-submenu",
        closeBtnClass   = "megamenu__close",
        selected        = "page-selected",
        burgerOrigClass = "megamenu__burger-orig",
        searchBoxClose  = "search-box__close";

    //selector variable
    var menuL2       = "ul.megamenu__list-l2",
        menuL3       = "ul.megamenu__list-l3",
        landing      = "li.landing-page",
        currSession  = "li.current-session",
        currentSel   = ".header-current-class";

    //selector obj
    var $menuBtnWrap = $(".megamenu__burger-wrap"),
        $burgerBtn   = $("#megamenu__burger"),
        $backBtn     = $("a.megemenu__back-arrow"),
        $menuL1      = $("ul.megamenu__list"),
        $menuL2      = $("ul.megamenu__list-l2"),
        $menuL3      = $("ul.megamenu__list-l3"),
        $menuItem    = $("ul.megamenu__list li"),
        $level1Item  = $("ul.megamenu__list > li > a"),
        $level2Item  = $("ul.megamenu__list-l2 > li > a"),
        $level3Item  = $("ul.megamenu__list-l3 > li:not(.landing-page) > a"),
        $menuWrap    = $(".megamenu-wrap"),
        $html        = $("html"),
        $searchBoxToggle = $(".search-box__toggle"),
        searchActive    = "header__search--active",
        $searchBox       = $(".header__search"),
        $delwpMenuItem = $(".delwp__menu-wrap a");

    var $menuBtn        = $(".delwp__menu-btn"),
        $departmentMenu = $(".delwp__menu-wrap"),
        $govLogo        = $(".header__logo");

    $burgerBtn.click(function(event) {
        event.preventDefault();
        if($menuBtnWrap.hasClass(active)) {
            //close menu, remove all active class, reset all data variable
            $menuBtnWrap.removeClass(active);
            $menuWrap.removeClass(active);
            $menuL1.removeClass(active).removeClass(current);
            $menuL2.removeClass(active).removeClass(current);
            $menuL3.removeClass(active).removeClass(current);
            //$backBtn.text(backMenuDef);
            $backBtn.hide();
            $(this).removeClass(closeBtnClass);
            $html.removeClass("no-scroll");
            $(this).addClass(burgerOrigClass);
            $(this).blur();

        } else {
            $departmentMenu.removeClass(active);
            prepSubmenuBeforeOpen();
            $menuBtnWrap.addClass(active);
            $menuWrap.addClass(active);
            $html.addClass("no-scroll");

            $(this).removeClass(burgerOrigClass);
            $(this).addClass(closeBtnClass);
            $(this).blur();
        }
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    });

    //$menuBtnWrap
    $backBtn.click(function(event) {
        event.preventDefault();
        if($(currentSel).length == 0 || $(currentSel).hasClass(menuL1ClassName)) {
            //menu level 1
            $menuBtnWrap.removeClass(active);
            $menuWrap.removeClass(active);
            $menuL1.removeClass(active).removeClass(current);
            updateBackBtn(backMenuDef, false);
        } else {
            $(currentSel).removeClass(active).removeClass(current).parent("li").parent("ul").addClass(current);
            prepCurrentMenu($(currentSel));
            $(currentSel).addClass(active);
        }
    });

    //level1Item
    $level1Item.click(function(event) {
        if($(this).parent("li").hasClass(submenuClass)) {
            event.preventDefault();
            menuItemClick($(this));
        }
    });

    $level2Item.click(function(event) {
        if($(this).parent("li").hasClass(submenuClass)) {
            event.preventDefault();
            menuItemClick($(this));
        }
    });

    $level3Item.click(function(event) {
        if($(this).parent("li").hasClass(submenuClass)) {
            event.preventDefault();
            menuItemClick($(this));
        }
    });

    //main-site menu
    $(".delwp__menu-btn").click(function(event) {
        //open delwp site menu
        event.preventDefault();
        $menuBtn.toggleClass(active);
        $departmentMenu.toggleClass(active);
    });

    var menuItemClick = function($menuItem) {
        $(currentSel).removeClass(current);
        $menuItem.siblings("ul").addClass(current);
        prepCurrentMenu($(currentSel));
        $(currentSel).addClass(active);
    }

    //prepare submenu indicator
    var prepSubmenuBeforeOpen = function() {
        //if page is selected
        var isPageSelted = false;
        var menuItemText = "",
            title        = "";

        $menuItem.each(function() {
            findSubmenu($(this));
            if($(this).hasClass(selected)) {
                $(this).parent("ul").addClass(current);
                isPageSelted = true;
                prepCurrentMenu($(currentSel));
                $(this).parents("ul").addClass(active);
            }
        });
        if(!$menuL1.hasClass(active)) {
            $menuL1.addClass(active);
        }

        if(!isPageSelted) {
            $menuL1.addClass(current);
        }
    }

    //add submenu indicator
    var findSubmenu = function(thisObj) {
        if (thisObj.children("ul").length == 1) {
            thisObj.addClass(submenuClass);
        }
    }

    //$ele current menu
    var prepCurrentMenu = function($ele) {
        var parentMenu = getParentMenu($ele);
        if($ele.children(landing).length == 1) {
            $ele.prepend($ele.children(landing));
        } else if($ele.children(landing).length < 1 && $ele.hasClass(menuL3ClassName)) {
            //current currSession
            $ele.children(currSession).remove();
            if(parentMenu.hasOwnProperty('entryText')) {
                $ele.prepend($("<li/>", { "class": currentSession })
                    .append($("<a/>", {text: parentMenu.entryText + " home" }).attr('href', parentMenu.entryLink)));
            }
        }

        if(parentMenu.hasOwnProperty('parentMenu')) {
            var grandParentMenu = getParentMenu(parentMenu.parentMenu);

            if(grandParentMenu.hasOwnProperty('parentMenu')) {
                //level 3
                updateBackBtn(grandParentMenu.entryText, true);
            } else {
                //no grand parent - level 2
                updateBackBtn(backDefText, true);
            }
        } else {
            //no parent - level 1
            updateBackBtn(backMenuDef, false);
        }
    }

    var updateBackBtn = function(btnText, showArrow) {
        $backBtn.children("span").text(btnText);

        if(showArrow === true) {
            $backBtn.show();
        } else {
            $backBtn.hide();
        }

    }

    //$ele current menu
    var getParentMenu = function($ele) {
        var entry      = $ele.parent("li"),
            entryText  = "",
            entryLink  = "";
        var parentMenu = $ele.parent("li").parent("ul"),
            parent     = {};

        if(entry.length > 0) {
            //not level 1 menu
            if(parentMenu.hasClass(menuL2ClassName)) {
                entryText = $.trim(entry.children("a").children("span").text());
                entryLink = $.trim(entry.children("a").attr('href'));
            } else {
                entryText = $.trim(entry.children("a").text());
                entryLink = $.trim(entry.children("a").attr('href'));
            }

            parent.parentMenu = parentMenu;
            parent.entryText  = entryText;
            parent.entryLink  = entryLink;
        }

        return parent;
    }

    //search box toggle for mobile and tablet
    $searchBoxToggle.on("click", function() {
        $(this).toggleClass(searchBoxClose);
        if ($searchBox.hasClass(searchActive)) {
            $searchBox.slideUp(300, function(){
            });
            setTimeout(function() {
                $searchBox.removeClass(searchActive);
            }, 301);

        } else {
            $searchBox.addClass(searchActive);
            $searchBox.slideDown(300, function(){
            });
        }
    });

    $delwpMenuItem.on("click", function(){
        $departmentMenu.removeClass(active);
    });
    

}(jQuery));
