/*global jQuery*/
(function ($) {
    'use strict';

    if(window.location.hash.length > 0 && $(".topic-tiles").length > 0){
        var id = window.location.hash;
        if($(id).length == 1 && (typeof $(id).attr('href') != 'undefined')) {
            $.magnificPopup.open({
                items: {},
                type: 'ajax',
                preloader: false,
                ajax: {
                    settings: {
                        url: $(id).attr('href') + '?SQ_DESIGN_NAME=blank'
                    }
                },
                callbacks: {
                     beforeOpen: function() {
                     }
                }
            });
        }
    }

    $('.topic-tile__block:not(.topic-tile__direct-link)').magnificPopup({
        type: 'ajax',
        preloader: false,
        callbacks: {
            elementParse: function(items) {
                items.src = $(this)[0].items[$(this)[0].index].src + '?SQ_DESIGN_NAME=blank';
            },
            open: function(item) {
                window.location.hash = $(this)[0].items[$(this)[0].index].el[0].id;
            },
            beforeOpen: function() {
                $(this.items[this.index]).addClass('loading')
            },
            ajaxContentAdded: function() {
                $('.topic-tile__block').removeClass('loading')
                $("html").addClass("no-scroll");
            },
            close: function() {
                $("html").removeClass("no-scroll");
                if (location.hash) history.go(-1);
            }
        }

    });

    window.onhashchange = function() {
        if (!window.innerDocClick) {
            if(window.location.hash.length < 1) {
                $.magnificPopup.close();
                $('.topic-tile__block').blur();
            }  
        }
    }
}(jQuery));
