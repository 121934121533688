/*global jQuery, Slideout, viewport*/
(function ($) {
    'use strict';

    var $menuBurgerButton   = $('a#header__menu-burger'),
        $expandSubmenuBtn   = $('nav#navigation .navigation__cta'),
        $megamenuWrapper    = $('nav#megamenu'),
        $searchMobileExpand = $('a#header__search-icon'),
        $searchMobileForm   = $('div#header__search-mobile'),
        $searchMobileInput  = $('input#header__search-input'),
        $skipLink           = $('div#top a[href="#navigation"]'),
        $navFirstFocusable  = $('nav#navigation :focusable'),
        $nav                = $('nav#navigation'),
        slideout,
        slideOptions = {
            'panel':     document.getElementById('page-wrapper'),
            'menu':      document.getElementById('navigation'),
            'padding':   268,
            'side':     'right',
            'tolerance': 200,
            'touch':     false
        };

    // Get viewport and modify slideout padding accordingly 
    window.addEventListener('resize', () => {

        if (viewport.getType() === 'global' && typeof slideout !== 'undefined') {

            slideout.destroy();
            if(document.querySelector('a#header__menu-burger')) {
                document.querySelector('a#header__menu-burger').classList.remove("header__menu-burger--active");
                slideOptions.padding = 268;
                $.slideoutInst = slideout = new Slideout(slideOptions);
            }

        } else if ((viewport.getType() === 'medium' || viewport.getType() === 'wide') && typeof slideout !== 'undefined') {
            
            slideout.destroy();
            if(document.querySelector('a#header__menu-burger')) {
                document.querySelector('a#header__menu-burger').classList.remove("header__menu-burger--active");
                slideOptions.padding = 320;
                $.slideoutInst = slideout = new Slideout(slideOptions);
            }
        } 
    });
    
    switch (viewport.getType()) {
    case 'global':
        slideOptions.padding = 268;
        break;
    case 'medium':
        slideOptions.padding = 320;
        break;
    case 'wide':
        slideOptions.padding = 320;
        break;
    default:
        slideOptions.padding = 268;
    }
    
    if ($nav.length) {

        // slideout.js plugin call
        $.slideoutInst = slideout = new Slideout(slideOptions);

        // Toggle menu on skip link
        $skipLink.on('click', function (e) {
            e.preventDefault();

            slideout.toggle();
            $navFirstFocusable
                .first()
                .focus();
            $menuBurgerButton
                .toggleClass('header__menu-burger--active')
                .find('span.sr-only')
                .toggleText('Open menu', 'Close menu');

        });

        // Toggle search on mobile
        $searchMobileExpand.on('click', function (e) {
            e.preventDefault();

            $searchMobileForm
                .slideToggle('fast')
                .toggleClass('header__search-mobile--active');
            $searchMobileInput
                .focus();

        });

        // Trigger slideout menu
        $menuBurgerButton.on('click', function (e) {
            e.preventDefault();

            $(this)
                .toggleClass('header__menu-burger--active')
                .find('span.sr-only')
                .toggleText('Open menu', 'Close menu');
            slideout.toggle();
        });

        // Expand/collapse slideout submenus
        $expandSubmenuBtn.on('click', function (e) {
            e.preventDefault();

            // Get the relative position of submenus as we are scrolling the #nav element, not the html, body
            var scrollValue = $(this).siblings('.navigation__link, .navigation__sub-l2-link, .navigation__sub-l3-link').offset().top - $nav.find('.navigation__list').offset().top;

            $(this)
                .siblings('ul')
                .slideToggle('fast')
                .end()
                .toggleClass('navigation__cta--active');

            // Scroll to a submenu on expand
            if ($(this).hasClass('navigation__cta--active')) {
                $nav.animate({
                    scrollTop: scrollValue
                }, 500);
            }
        });

        // Disable clickable nav link if it's folder asset
        $nav.find('.Folder').on('click', function (e) {
            e.preventDefault();
        });

        // accessible mega menu plugin call
        $megamenuWrapper.accessibleMegaMenu({
            // prefix for generated unique id attributes
            uuidPrefix: "accessible-megamenu",

            // css class used to define the megamenu styling
            menuClass: "megamenu__list",

            // css class for a top-level navigation item in the megamenu
            topNavItemClass: "megamenu__item",

            // css class for a megamenu panel
            panelClass: "megamenu__sub-nav",

            // css class for a group of items within a megamenu panel
            panelGroupClass: "megamenu__sub-group",

            // css class for the hover state
            hoverClass: "hover",

            // css class for the focus state
            focusClass: "focus",

            // css class for the open state
            openClass: "megamenu__link--open"
        });
    }

    $(document.body).on('click', function (e) {
        // console.log(e.target);
    });
}(jQuery));