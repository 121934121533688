/*global jQuery*/
(function ($) {
    'use strict';

    if($(".lhs-nav-wrapper-2018").length > 0 ) {
        $(".lhs-nav-wrapper-2018 li").each(function(){
            if($(this).children("ul").length == 1) {
                $(this).addClass("has-sublist");
                $(this).append($("<button/>", {"class": "lhs-nav__cta-2018"}).append($("<span/>", {"class": "sr-only", text:  "Open submenu" })));
            }
        });
    }

    var $lhsMenuTrigger = $('.lhs-nav-wrapper-2018 .lhs-nav__cta-2018');

    $lhsMenuTrigger.on('click', function (e) {
        e.preventDefault();

        $(this).parent("li.has-sublist").toggleClass("active")
               .children('span.sr-only').toggleText('Open submenu', 'Close submenu');
    });

}(jQuery));
