/* eslint-disable */
(function () {
    const SearchableFeaturedGrid = (settings = {}) => {

        // Helper functions

        // __selectElement - check if provide selector or string and return selector
        const __selectElement = el => {
            return typeof el !== 'string' ? el : document.querySelectorAll(el).length > 1 ? document.querySelectorAll(el) : document.querySelector(el);
        };

        // __changeIdToName - changing data-id value to name (first letter of each word set to upper case)
        const __changeIdToName = el => el.toLowerCase().replace(/_/g, ' ').split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');

        // __resultsState - add object from results
        const __resultsState = (sections, results) => {
            if (typeof sections.length === 'undefined') {
                return [{
                    sections: sections,
                    results: sections.querySelectorAll(results)
                }];
            }

            return Array.from(sections).map(section => {
                return {
                    sections: section,
                    results: section.querySelectorAll(results)
                };
            });
        };

        // State object
        const searchableState = {
            search: __selectElement(settings.search) ? __selectElement(settings.search) : null,
            filterDropdown: __selectElement(settings.filter) ? __selectElement(settings.filter) : null,
            filterWrapper: __selectElement(settings.filterWrapper) ? __selectElement(settings.filterWrapper) : null,
            filterNumber: __selectElement(settings.filterNumber) ? __selectElement(settings.filterNumber) : null,
            sections: __selectElement(settings.sections) ? __selectElement(settings.sections) : null,
            initResults: __selectElement(settings.results) ? __resultsState(__selectElement(settings.sections), settings.results) : null,
            message: settings.message,
            counter: 0,
            desc: settings.desc,
            title: settings.title
        };

        // __addGetParam - set get url parametr
        const __addGetParam = (key, value) => {
            const encodeParam = `${key}=${encodeURIComponent(value)}`;
            const regExp = new RegExp(`${key}[^&]*`, 'i');

            if (key === undefined && value === undefined) {
                return window.history.pushState({
                    ...history.state,
                    'searchableFilter': 'all'
                }, null, window.location.pathname);
            }
            // if no parametrs
            if (!window.location.search) {
                return window.history.pushState({
                    ...history.state,
                    [key]: value
                }, null, `?${encodeParam}`);
            }
            // add new parametr if at least one exist
            if (!window.location.search.match(regExp)) {
                return window.history.pushState({
                    ...history.state,
                    [key]: value
                }, null, window.location.search + `&${encodeParam}`);
            }
            //
            if (!window.location.search.match(encodeParam) || value === '' || window.location.search.match(regExp)[0] !== encodeParam) {
                return window.history.pushState({
                    ...history.state,
                    [key]: value
                }, null, window.location.search.replace(regExp, encodeParam));
            }
        };

        // __getParamValue - get url parametrs and return
        const __getParamValue = key => {
            const queryParams = window.location.search;
            if (queryParams.indexOf(key) === -1) {
                return false;
            }

            const regExp = new RegExp(`${key}[^&]*`, 'i');
            const paramValue = queryParams.match(regExp)[0].split('=')[1];

            return decodeURIComponent(paramValue);

        };

        // Filter
        // Helper functions for filter dropdown

        // __showSections - base on section ID show all or select section
        const __showSections = sectionId => {
            Array.from(searchableState.sections).map(section => sectionId === 'all' ? section.classList.remove('searchable-grid-section--hide') : section.getAttribute('data-id') === sectionId && section.classList.remove('searchable-grid-section--hide'));
        };

        // __hideSections - hide sections
        const __hideSections = sectionId => {
            Array.from(searchableState.sections).map(section => section.getAttribute('data-id') === sectionId && section.classList.add('searchable-grid-section--hide'));
        };

        // __removeNumber - remove number for filter for mobile view
        const __removeNumber = () => {
            searchableState.filterNumber.classList.remove('has-data');
            searchableState.filterNumber.innerHTML = '';
        };

        // __addNumber - add number for filter for mobile view
        const __addNumber = () => {
            searchableState.filterNumber.classList.add('has-data');
            searchableState.filterNumber.innerHTML = '1';
        };

        // __createDropdownList - takes data-id attribute for each sections 
        // and return array of object with id = data-id and value = name of data-id
        const __createDropdownList = () => {
            const filterDropdownList = typeof searchableState.sections.length === 'undefined' ? [{
                id: searchableState.sections.getAttribute('data-id'),
                name: __changeIdToName(searchableState.sections.getAttribute('data-id'))
            }] : Array.from(searchableState.sections).map(section => {
                return {
                    id: section.getAttribute('data-id'),
                    name: __changeIdToName(section.getAttribute('data-id'))
                };
            });

            return searchableState.filterDropdownList = filterDropdownList;
        };

        // __renderDropdownList - render and insert to DOM dropdown list
        // base on filterDropdownList array 
        const __renderDropdownList = () => {
            const renderDropdownList =
                `
                <ul class="filter-field__dropdown-list">
                    <li data-option="all">All</li>
                    ${searchableState.filterDropdownList.map(li => `<li data-option="${li.id}">${li.name}</li>`).join('')}
                </ul>
            `;

            return searchableState.filterDropdown.insertAdjacentHTML('beforeend', renderDropdownList);
        };

        const __renderFilterResults = e => {
            searchableState.filterDropdownList.filter(el => {
                if (el.id === e.getAttribute('data-option')) {
                    __addGetParam('searchableFilter', el.id);
                    __showSections(el.id);
                    __addNumber();
                    searchableState.filterDropdownValue = el.id;
                    searchableState.filterDropdown.querySelector('input').value = el.name;
                } else {
                    __hideSections(el.id);
                    __addNumber();
                }
            });
        };

        const __renderAllFilterResults = () => {
            __addGetParam('searchableFilter', 'all');
            __showSections('all');
            __removeNumber();
            searchableState.filterDropdownValue = 'all';
            searchableState.filterDropdown.querySelector('input').value = 'All';
        };

        function __dropdownEventHandler(element) {
            Array.from(searchableState.filterDropdown.querySelectorAll('li')).map(el => el.classList.remove('selected'));

            if (!element) {
                searchableState.filterDropdown.querySelector('input').value = '';
                __showSections('all');
                __removeNumber();
                return;
            }

            const e = typeof element.target === 'undefined' ? element : element.target;

            e.classList.add('selected');

            searchableState.filterDropdown.classList.remove('active');

            // Render results by filter
            e.getAttribute('data-option') === 'all' ? __renderAllFilterResults() : __renderFilterResults(e);

            // Check query
            __searchEventHandler(searchableState.search.querySelector('input'));
        };

        const __mobileSubmitDropdownListEvent = () => {
            if (searchableState.filterWrapper) {
                searchableState.filterWrapper.querySelector('button[type="submit"]').addEventListener('click', e => {
                    e.preventDefault();
                    document.querySelector('html').classList.remove('no-scroll');
                    searchableState.filterWrapper.classList.remove('active');
                });
            }
        };

        const __mobileClearButton = () => {
            if (searchableState.filterWrapper.querySelector('#search-filters-clear-btn')) {
                searchableState.filterWrapper.querySelector('#search-filters-clear-btn').addEventListener('click', e => {
                    __dropdownEventHandler()
                    __addGetParam();
                });
            }
        }

        const __addActionsToDropdownList = () => {
            Array.from(searchableState.filterDropdown.querySelectorAll('li')).map(el => el.addEventListener('click', e => __dropdownEventHandler(e)));
            __mobileSubmitDropdownListEvent();
            __mobileClearButton();
        };

        const __setFilter = () => {
            if (__getParamValue('searchableFilter')) {
                const dataId = __getParamValue('searchableFilter');
                const selectElementFromQuery = document.querySelector(`[data-option='${dataId}']`);

                __dropdownEventHandler(selectElementFromQuery);

                searchableState.filterDropdownValue = dataId;
            } else {
                searchableState.filterDropdownValue = 'all';
            }
        };

        const __searchablefilter = () => {
            if (searchableState.filterDropdown) {
                __createDropdownList();
                __renderDropdownList();
                __setFilter();
                __addActionsToDropdownList();
            }
        };

        // Search
        const __noResultsShow = () => {
            if (searchableState.result.length === searchableState.counter) {
                document.querySelector(searchableState.message).classList.remove('searchable-no-results--hide');
            }
        };

        const __noResultsHide = () => {
            document.querySelector(searchableState.message).classList.add('searchable-no-results--hide');
        };

        const __renderAllResults = () => {
            searchableState.initResults.map(initResult => {
                // initResult.sections.querySelector('.searchable-grid .row').innerHTML = '';
                Array.from(initResult.results).map(results => {
                    initResult.sections.querySelector('.searchable-grid .row').appendChild(results);
                });
            });
        };

        const __renderSearchResult = () => {
            const regex = new RegExp(searchableState.query, 'i');

            // Create new result object
            searchableState.result = Object.create(searchableState.initResults);

            searchableState.result = searchableState.result.map(el => {
                return {
                    sections: el.sections,
                    newResults: Array.from(el.results).filter(result => {
                        const titleHTML = result.querySelector(searchableState.title) ? result.querySelector(searchableState.title).innerHTML : '';
                        const descHTML = result.querySelector(searchableState.desc) ? result.querySelector(searchableState.desc).innerHTML : '';

                        if (regex.test(result.getAttribute('data-search')) || regex.test(titleHTML) || regex.test(descHTML)) {
                            return result;
                        } else {
                            result.parentNode.removeChild(result);
                        }
                    })
                };
            });
        };

        const __showHideSections = () => {
            // Reset counter state
            searchableState.counter = 0;

            searchableState.result.map(result => {
                if (result.newResults.length > 0 && (result.sections.getAttribute('data-id') === searchableState.filterDropdownValue || searchableState.filterDropdownValue === 'all')) {
                    result.sections.classList.remove('searchable-grid-section--hide');
                } else {
                    searchableState.counter = searchableState.counter + 1;
                    result.sections.classList.add('searchable-grid-section--hide');
                }
            });

            __noResultsShow();
        };

        function __searchEventHandler(e) {
            const value = typeof e.target !== 'undefined' ? e.target.value : e.value;

            // Create query state
            searchableState.query = value;

            if (searchableState.initResults) {
                // Reset view - render all init results
                __renderAllResults();
                // Hide No result found message
                __noResultsHide();
                // // Render search results
                __renderSearchResult();
                // // Toggle section view and if no result display masssage
                __showHideSections();
            }
        };

        const __setQuery = () => {
            const input = searchableState.search.querySelector('input');
            input.value = __getParamValue('query') ? __getParamValue('query') : '';
            __searchEventHandler(input);
        };

        const __addActionsToSearch = () => {
            // Prevent submit form
            searchableState.search.addEventListener('submit', e => {
                e.preventDefault();
                __addGetParam('query', searchableState.query);
            });

            searchableState.search.querySelector('input').addEventListener('keyup', e => __searchEventHandler(e));
        };

        const __popstateEvent = () => {
            window.addEventListener('popstate', e => {
                if (e.state) {
                    __dropdownEventHandler(document.querySelector(`[data-option='${e.state.searchableFilter}']`));
                    __setQuery();
                } else {
                    __dropdownEventHandler(e.state);
                    __setQuery();
                }
            });
        };

        const __searchableSearch = () => {
            __addActionsToSearch();
            __setQuery();
        };

        // Init
        const init = () => {
            if (searchableState.initResults && searchableState.search) {
                __searchablefilter();
                __searchableSearch();
                __popstateEvent();
            }
        };

        const debug = () => {

            console.log('Debug method Searchable Featured Grid component state: ', searchableState);
            if (!searchableState.filterDropdown) {
                console.error('Dropdown filter not found. Please check filterEl selector.');
            }

            if (!searchableState.sections) {
                console.error('Sections not found. Please check sectionsEl selector.');
            }
        };

        return Object.freeze({
            debug,
            init
        });
    };

    const searchableFeaturedGrid = SearchableFeaturedGrid({
        search: '#js-searchable-search',
        filterWrapper: '#js-searchable-search-filters',
        filter: '#js-searchable-filter-dropdown',
        filterNumber: '#js-searchable-search-filters-number',
        sections: '.js-searchable-grid-section',
        results: '.js-searchable-grid-result',
        message: '.js-searchable-no-results',
        title: '[data-searchable-title]',
        desc: '[data-searchable-description]'
    });

    searchableFeaturedGrid.init();
}());

(function ($) {
    'use strict';
    // toggle dropdown filter on searchable-feature-grid
    var active = "active",
        $dropdownTrigger = $('.searchable-filter-field__dropdown-value'),
        filterFieldSelector = ".filter-field"
        
    $dropdownTrigger.on("click", function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(this).parents(filterFieldSelector).toggleClass(active);
    });

    // Hide the dropdown lists when focus is lost.
    $dropdownTrigger.on("blur", function (e) {
        $(this).parents(filterFieldSelector).removeClass('active');
    });

    // Prevent interactions on child elements from triggering the "blur" event.
    $(".filter-field__dropdown-list").on("mousedown click", function (e) {
        e.preventDefault();
        e.stopPropagation();
    });
    
}(jQuery));