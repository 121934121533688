$(document).ready(function() {
  //custom accordion
  $(document).on("click", ".accordion-button-js", function(e) {
    e.preventDefault();
    $(this)
      .parent()
      .parent()
      .toggleClass("open");
  });

  //reset all menu accordion
  $(document).on("click", ".side-menu-reset-button", function(e) {
    e.preventDefault();
    $(this)
      .parent()
      .next()
      .find(".open")
      .removeClass("open");
  });
});

var planningSchemeDrawer = {};

(function($) {
  "use strict";

  // Settings

  planningSchemeDrawer.settings = {
    URLSuffix:
      "?SQ_PAINT_LAYOUT_NAME=planning_sheme_drawer&SQ_DESIGN_NAME=blank", // Page content for content drawer
    pushPopSupport:
      undefined !== history.pushState && undefined !== window.onpopstate
        ? true
        : false, // pushState support
    xhr: undefined // Ajax call object
  };

  // Selectors
  planningSchemeDrawer.selectors = {
    callLinks: ".planning-scheme-drawer-open-js", // Trigger for content drawer
    wrapper: "#planning-scheme-drawer-js", // Parent element of drawer items
    content: "#planning-scheme-drawer-js", // Ajax response will be put here
    closeBtn: "#planning-scheme-close-js", // Close button, gains focus on drawer init
    loader: "#planning-scheme-loading-js", // Loader icon
    overlay: "#overlay",
    bodyActive: "planning-scheme-opened", // Class to be added on drawer open
    closeWrpr: "#content-drawer__close-wrapper" // not used
  };

  // Flags
  planningSchemeDrawer.flags = {
    inProgress: false, // call in progress
    opened: false, // call finished and drawer opened
    currentLoadedHref: undefined, // Last loaded href
    currentURL: undefined // Current URL
  };

  planningSchemeDrawer.init = function() {
    // Quit when no drawer placeholder is in place
    if (!$(planningSchemeDrawer.selectors.wrapper).length) {
      return false;
    }

    // Add hidden text to callLinks
    $(planningSchemeDrawer.selectors.callLinks).append(
      '<span class="sr-only">Open in slide out</span>'
    );

    $("body")
      // Set callLinks items
      .on("click", planningSchemeDrawer.selectors.callLinks, function(e) {
        e.preventDefault();

        planningSchemeDrawer.load($(this).attr("href"));

        var item = $(this);
        planningSchemeDrawer.resetAndSetActive(item);
      })
      // Set close
      .on("click", planningSchemeDrawer.selectors.closeBtn, function(e) {
        e.preventDefault();
        planningSchemeDrawer.hideDrawer();
      });

    // History back support
    planningSchemeDrawer.historyBackHandler();

    planningSchemeDrawer.lookForDrawer(window.location.hash, true);
  };

  planningSchemeDrawer.openPathToItem = function(item) {
    item.closest(".accordion__l1").addClass("open");
    item.closest(".accordion__l2").addClass("open");
    item.closest(".accordion__l3").addClass("open");
  };

  planningSchemeDrawer.resetAndSetActive = function(item) {
    $(".side-menu")
      .find(".active")
      .removeClass("active");
    item.addClass("active");
  };

  // Loader
  planningSchemeDrawer.loader = function(
    showLoader,
    showOverlay,
    inProgress,
    Opened
  ) {
    // Icon toggle
    if (showLoader) {
      $(planningSchemeDrawer.selectors.loader).fadeIn("fast"); // Show loader
      $(planningSchemeDrawer.selectors.loadingTxt).focus(); // Focus on loader
    } else if (!showLoader && undefined !== showLoader) {
      $(planningSchemeDrawer.selectors.loader).fadeOut("fast"); // Hide loader
    }

    // Overlay toggle
    if (showOverlay) {
      $(planningSchemeDrawer.selectors.overlay).addClass(
        "overlay--active-drawer"
      ); // Show overlay
    } else if (!showOverlay && undefined !== showOverlay) {
      $(planningSchemeDrawer.selectors.overlay).removeClass(
        "overlay--active-drawer"
      ); // Hide overlay
    }

    // Manage inProgress flag
    if (inProgress) {
      planningSchemeDrawer.flags.inProgress = true;
    } else if (!inProgress && undefined !== inProgress) {
      planningSchemeDrawer.flags.inProgress = false;
    }

    // Manage Opened flag
    if (Opened) {
      planningSchemeDrawer.flags.opened = true;
    } else if (!Opened && undefined !== Opened) {
      planningSchemeDrawer.flags.opened = false;
    }
  };

  // Load - ajax call
  planningSchemeDrawer.load = function(href) {
    var $currCallLink = $(
      planningSchemeDrawer.selectors.callLinks + '[href="' + href + '"]'
    );

    // Abort on #
    if (href === "#") {
      return false;
    }

    if (undefined !== planningSchemeDrawer.settings.xhr) {
      planningSchemeDrawer.settings.xhr.abort();
    }

    // Show loader
    planningSchemeDrawer.loader(true, true, true, false);

    // Make ajax call
    planningSchemeDrawer.settings.xhr = $.ajax({
      // url: href + planningSchemeDrawer.settings.URLSuffix,
      url: href,
      dataType: "html"
    })
      .done(function(data) {
        // Set flag
        planningSchemeDrawer.flags.currentLoadedHref = href;
        planningSchemeDrawer.flags.currentURL = window.location.href;

        // Call success handler
        planningSchemeDrawer.loaded(data, href);
      })
      .fail(function(textStatus) {
        planningSchemeDrawer.loader(false, false, false, false);

        // Do not render content drawer on XHR status code 0 (abort)...
        if (0 !== textStatus.status) {
          // Frontend msg
          planningSchemeDrawer.loaded(
            '<div class="content-drawer__content"><h1>A ' +
              textStatus.status +
              " error occured: " +
              textStatus.statusText +
              "</h1>",
            href
          );
        } else {
          // ...Give user a 'click once' hint instead
          $currCallLink.addClass("content-drawer__link--double-clicked");
        }
      });
  };

  // Ajax success handler
  planningSchemeDrawer.loaded = function(data, href) {
    // Clear content HTML
    $(planningSchemeDrawer.selectors.content)
      .html("")
      // Put response into drawer
      .append(data);

    // Init plugin on accordions inside of content drawer
    accordions.init(true);

    // Show content drawer
    planningSchemeDrawer.showDrawer(href);
  };

  // Show content drawer
  planningSchemeDrawer.showDrawer = function(href) {
    // Prevent body from scrolling
    $("body").addClass(planningSchemeDrawer.selectors.bodyActive);

    // Show drawer
    $(planningSchemeDrawer.selectors.wrapper).show();

    // Hide loader
    planningSchemeDrawer.loader(false, true, true, false);

    // Slide out drawer
    $(planningSchemeDrawer.selectors.wrapper).animate(
      {
        right: "0"
      },
      500,
      function() {
        // Change focus
        $(planningSchemeDrawer.selectors.closeBtn).focus();

        // Handle URL
        planningSchemeDrawer.URLHandler(true, href);

        $(planningSchemeDrawer.selectors.content).scroll(function() {
          if ($(this).scrollTop()) {
            $(planningSchemeDrawer.selectors.closeWrpr).addClass(
              "content-drawer__close-wrapper--scrolled"
            );
          } else {
            $(planningSchemeDrawer.selectors.closeWrpr).removeClass(
              "content-drawer__close-wrapper--scrolled"
            );
          }
        });

        // Set as finished
        planningSchemeDrawer.loader(false, false, false, true);
      }
    );
  };

  // Close content drawer
  planningSchemeDrawer.hideDrawer = function() {
    if (planningSchemeDrawer.flags.opened) {
      // Set flags
      planningSchemeDrawer.loader(false, true, true, false);

      // Hide drawer
      $(planningSchemeDrawer.selectors.wrapper).animate(
        {
          right: -$(planningSchemeDrawer.selectors.wrapper).width()
        },
        500,
        function() {
          // Remove class from body element
          $("body").removeClass(planningSchemeDrawer.selectors.bodyActive);

          $(planningSchemeDrawer.selectors.wrapper).hide("fast", function() {
            // Handle URL
            planningSchemeDrawer.URLHandler(false);

            // Set as finished
            planningSchemeDrawer.loader(false, false, false, false);
          });
        }
      );
    } else {
      // Cancel xhr
      if (undefined !== planningSchemeDrawer.settings.xhr) {
        planningSchemeDrawer.settings.xhr.abort();
      }

      // Set flags
      planningSchemeDrawer.loader(false, false, false, false);
    }
  };

  // URL hash handler
  planningSchemeDrawer.URLHandler = function(setURL, href) {
    var currTitle;

    if (setURL) {
      // Find element that called drawer, get its title
      currTitle = $(
        planningSchemeDrawer.selectors.callLinks + '[href="' + href + '"]'
      )
        .attr("title")
        .replace(/ /g, "_");

      // pushState support
      if (planningSchemeDrawer.settings.pushPopSupport) {
        history.pushState(null, null, "#" + currTitle);
      } else {
        // pushState fallback
        window.location.hash = currTitle;
      }
    } else {
      if (planningSchemeDrawer.settings.pushPopSupport) {
        history.replaceState(
          "",
          document.title,
          window.location.pathname + window.location.search
        );
      } else {
        window.location.hash = "";
      }
    }
  };

  // Handle URL, popstate
  planningSchemeDrawer.historyBackHandler = function() {
    if (window.innerWidth < 1024) {
      // popState support
      if (planningSchemeDrawer.settings.pushPopSupport) {
        window.onpopstate = function() {
          if (planningSchemeDrawer.flags.opened) {
            planningSchemeDrawer.hideDrawer();
          } else {
            planningSchemeDrawer.lookForDrawer(window.location.hash);
          }
        };
      } else {
        // popState fallback
        window.onhashchange = function() {
          if (planningSchemeDrawer.flags.opened) {
            planningSchemeDrawer.hideDrawer();
          } else {
            planningSchemeDrawer.lookForDrawer(window.location.hash);
          }
        };
      }
    } else {
      window.onhashchange = function() {
        planningSchemeDrawer.lookForDrawer(window.location.hash);
      };
    }
  };

  // Open drawer on page load
  planningSchemeDrawer.lookForDrawer = function(hash, byLink) {
    var trgrElem;

    if (hash.length) {
      // Check if link actually exist on the page
      trgrElem = $(
        planningSchemeDrawer.selectors.callLinks +
          '[title="' +
          hash.replace(/#/g, "").replace(/_/g, " ") +
          '"]'
      );

      if (byLink) {
        // Do active
        planningSchemeDrawer.resetAndSetActive(trgrElem);
        planningSchemeDrawer.openPathToItem(trgrElem);
      }

      // Return false when no link with current hash is found
      if (hash === "#navigation" || !trgrElem.length) {
        return false;
      } else {
        // Open drawer
        planningSchemeDrawer.load(trgrElem.attr("href"));
      }
    }
  };

  $(document).ready(function() {
    planningSchemeDrawer.init();
  });

  // area map open accordion

  $("#pso-body-tab-planningSchemeMaps-map area").on("click", function() {
    var areahref = $(this).attr("href");
    $(".side-menu")
      .find(areahref)
      .addClass("open");
  });

  // add class active on active tab for Matrix Imp
  var current = location.pathname;
  $(".search-tabs__item a").each(function() {
    var $this = $(this);
    // if the current path is like this link, make it active
    if ($this.attr("href").indexOf(current) !== -1) {
      $this.parent().addClass("active");
    }
  });
  // add class active on histories tab on histories pages
  if (current.includes("/browse-planning-scheme/histories/")) {
    $(".search-tabs-wrap li.search-tabs__item")
      .last()
      .addClass("active");
  }

  // hide unwanted links on breadcrumb
  if (current.includes("/browse-planning-scheme/")) {
    var $browsePlanningSchemeItem = $(
      '.breadcrumb .breadcrumb__item a[href$="/browse-planning-scheme"]'
    );
    $browsePlanningSchemeItem
      .parent()
      .nextAll()
      .remove();
    // replace Browse planning scheme link on breadcrumb to callback page URL
    var callbackpageURL = $(".callback-page").attr("href");
    if (callbackpageURL.length > 0) {
      $browsePlanningSchemeItem.attr("href", callbackpageURL);
    }
    // redirect all children pages of /browse-planning-scheme/ without parameter to callback page
    var planningschemeURL = window.location.href;
    if (planningschemeURL.includes("planningSchemeName=")) {
      // do nothing
    } else {
      // do redirect
      var callbackpageURL = $(".callback-page").attr("href");
      if (callbackpageURL.length > 0) {
        window.location.replace(callbackpageURL);
      }
    }
  }
  // redirect /browse-planning-scheme page to callback page
  if (current.endsWith("/browse-planning-scheme")) {
    var callbackpageURL = $(".callback-page").attr("href");
    if (callbackpageURL.length > 0) {
      window.location.replace(callbackpageURL);
    }
  }

  // prevent submit form by pressing enter on keyboard
  if ($("#lga-selector").length > 0) {
    document
      .querySelector("#lga-selector")
      .addEventListener("keypress", function(e) {
        if (e.keyCode === 13) {
          e.preventDefault();
          return false;
        }
      });
  }

  // accordion base on matrix imp markup
  // move NA group to top
  $(".accordion__l1").each(function() {
    var $this = $(this);
    var value = $this.data("value");
    if (value === "NA") {
      $this.prependTo(".side-menu");
      // sorting NA & clause in NA group
      var $NAL2 = $this.find(".accordion__l2");
      $NAL2.each(function() {
        var $this = $(this);
        var valueL2 = $this.data("value");
        if (valueL2 === "NA" || valueL2 === "amlist") {
          const $NAL2Content = $this.parent(".accordion__l1-content");
          $this.prependTo($NAL2Content);
        }
      });
    }
  });

  function applyClass(callback) {
    $(".accordion__l2").each(function() {
      var $this = $(this);
      var value = $this.data("value");
      if (value) {
        var $dataValue = value.toString().split(".");
        if ($dataValue.length === 1) {
          $this.addClass("indent__l1");
        } else if ($dataValue.length === 2) {
          if ($dataValue.indexOf("00") === 1 || $dataValue.indexOf("0") === 1) {
            $this.addClass("indent__l1");
          } else {
            $this.addClass("indent__l2");
          }
        }
      }
    });
    callback();
  }
  function createAccordion() {
    $(".indent__l1").each(function() {
      var $this = $(this);
      // check if there is any indent__l2 under indent__l1
      if ($this.next(".indent__l2").length > 0) {
        // wrap link in header
        var $l2link = $this.find(".accordion__l2-link");
        $l2link.wrap('<div class="accordion__l2-header"></div>');
        // insert +icon before link
        $(
          '<a href="#" class="accordion__l2-button accordion-button-js">open</a>'
        ).insertBefore($l2link);
        // move all indent__l2 to indent__l1
        $this.nextUntil(".indent__l1", ".indent__l2").appendTo($this);
        // wrap all indent__l2 in contend div
        $this
          .find(".indent__l2")
          .wrapAll('<div class="accordion__l2-content"></div>');
      }
    });
  }
  function sortItems() {
    //sorts items by data-value eg: VPP 45.03
    $(".accordion__l1-content").each(function() {
      var sortItems = $(this).find(".accordion__l2");
      sortItems
        .sort(function(a, b) {
          var itemA =
              $(a)
                .attr("data-value")
                .indexOf("-") === -1
                ? parseFloat(
                    $(a)
                      .attr("data-value")
                      .replace(/\-[^\.]*/, "")
                  )
                : parseFloat(
                    $(a)
                      .attr("data-value")
                      .replace(/\-[^\.]*/, "")
                  ) - 0.001,
            itemB =
              $(b)
                .attr("data-value")
                .indexOf("-") === -1
                ? parseFloat(
                    $(b)
                      .attr("data-value")
                      .replace(/\-[^\.]*/, "")
                  )
                : parseFloat(
                    $(b)
                      .attr("data-value")
                      .replace(/\-[^\.]*/, "")
                  ) - 0.001;
          return itemA < itemB ? -1 : itemA > itemB ? 1 : 0;
        })
        .appendTo($(this));
    });
  }

  //https://derekswingley.com/2018/05/08/better-javascript-string-sorting-with-collators/
  //useage - console.log(myArray.sort(collator.compare));
  var collator = new Intl.Collator(undefined, {
    numeric: true,
    sensitivity: "base"
  });

  function sortItemsNatural() {
    // sorts items by data-value eg: VPP 45.03 and then the title by adding a new sorting data attribute
    // by using the Intl.Collator we can sort numbers and strings naturally

    $(".accordion__l1-content").each(function() {
      $(this)
        .find(".accordion__l2")
        .each(function() {
          var sortTitle = $(this)
            .find("a")
            .attr("title");
          var sortDataVal = $(this).attr("data-value");
          var sortDataType = $(this).attr("data-type");
          var replaceSortType = sortDataType === "lga" ? "b" : "a";
          var newSortData = "";

          // DELWP require some odd ordering for 'category' items so
          // we cheat and replace their true values with something we
          // can control easier (A-Z) SDVIC-1789.
          switch (sortDataVal[sortDataVal.length - 1]) {
            case "S":
              newSortData = sortDataVal.replace(/[S]$/, "A");
              break;
            case "R":
              newSortData = sortDataVal.replace(/[R]$/, "B");
              break;
            case "L":
              newSortData = sortDataVal.replace(/[L]$/, "C");
              break;
            default:
              newSortData =
                sortDataVal + " " + replaceSortType + " " + sortTitle;
          }
          $(this).attr("data-sort-val", newSortData);
        });

      var sortItems = $(this).find(".accordion__l2");

      sortItems
        .sort(function(a, b) {
          var itemA = $(a).attr("data-sort-val");
          var itemB = $(b).attr("data-sort-val");

          return collator.compare(itemA, itemB);
        })
        .appendTo($(this));
    });
  }

  //   sortItems();
  sortItemsNatural();
  applyClass(createAccordion);

  // replace enlargement links - base on 5 enlargement links
  if ($(".planning-scheme__content-map-enlagrement li").length > 2) {
    var $enlargementLinkURL1 = $(
        ".planning-scheme__content-map-enlagrement li:nth-child(2) a"
      ).attr("href"),
      $enlargementLinkURL2 = $(
        ".planning-scheme__content-map-enlagrement li:nth-child(3) a"
      ).attr("href"),
      $enlargementLinkURL3 = $(
        ".planning-scheme__content-map-enlagrement li:nth-child(4) a"
      ).attr("href"),
      $enlargementLinkURL4 = $(
        ".planning-scheme__content-map-enlagrement li:nth-child(5) a"
      ).attr("href"),
      $enlargementLinkURL5 = $(
        ".planning-scheme__content-map-enlagrement li:nth-child(6) a"
      ).attr("href"),
      // $enlargmentArea1 = $('map area:nth-last-child(2)[href*="?"]'),
      // $enlargemntArea2 = $('map area[href*="?"]').last();
      $enlargmentArea1 = $("map area[alt~='1']"),
      $enlargmentArea2 = $("map area[alt~='2']"),
      $enlargmentArea3 = $("map area[alt~='3']"),
      $enlargmentArea4 = $("map area[alt~='4']"),
      $enlargmentArea5 = $("map area[alt~='5']");

    $enlargmentArea1.attr("href", $enlargementLinkURL1);
    $enlargmentArea2.attr("href", $enlargementLinkURL2);
    $enlargmentArea3.attr("href", $enlargementLinkURL3);
    $enlargmentArea4.attr("href", $enlargementLinkURL4);
    $enlargmentArea5.attr("href", $enlargementLinkURL5);
  } else {
    var $enlargementLinkURL1 = $(
        ".planning-scheme__content-map-enlagrement li:nth-child(2) a"
      ).attr("href"),
      $enlargmentArea1 = $('map area[href*="?"]').last();
    $enlargmentArea1.attr("href", $enlargementLinkURL1);
  }
  // enlargement links - replace a tag by span
  function highlightCurrentEnlargement() {
    var getVariables = location.search.replace("?", "").split("&"),
      i,
      found = false,
      $links = $(".planning-scheme__content-map-enlagrement a");

    for (i = 0; i < getVariables.length; i++) {
      getVariables[i] = getVariables[i].split("=");
    }

    if ($links.length) {
      for (i = 0; i < getVariables.length; i++) {
        if (getVariables[i][0] === "queries_enlargement_query") {
          $links.each(function() {
            if (
              $(this)
                .attr("href")
                .indexOf(getVariables[i][1]) !== -1
            ) {
              $(
                "<span class='enlargement-link-active'>" +
                  $(this).html() +
                  "</span>"
              ).insertAfter($(this));
              $(this).remove();

              found = true;
            }
          });
        }
      }

      if (!found) {
        var $link = $links.filter(":first");
        $(
          "<span class='enlargement-link-active'>" + $link.html() + "</span>"
        ).insertAfter($link);
        $link.remove();
      }
    }
  }

  highlightCurrentEnlargement();
})(jQuery);
