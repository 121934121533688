(function () {
    'use strict';

    const planningSchemeMap = {
        mapWrapper:   document.getElementById('planning-scheme__map') ? document.getElementById('planning-scheme__map') : '',
        australiaMap: null,
        mapDataUrl:   document.getElementById('planning-scheme__map') ? document.getElementById('planning-scheme__map').getAttribute('data-map-url') : '',
        mapOptions:   {
            scrollwheel: false,
            center:      {
                lat: -36.779592,
                lng: 145.266724
            },
            zoom:             7,
            mapTypeId:        typeof google !== 'undefined' ? google.maps.MapTypeId.ROADMAP : null,
            disableDefaultUI: true,
            zoomControl:      true
        },
        areaOptions: {
            hover: {
                fillOpacity: .4,
                fillColor:   '#00b7bd',
                strokeColor: '#00b7bd'
            },
            active: {
                fillColor:   '#855487',
                fillOpacity: .9,
                strokeColor: '#855487'
            },
            defaults: {
                fillColor:   '#855487',
                fillOpacity: .2,
                strokeColor: '#855487'
            },
            init: {
                fillColor:     '#855487',
                fillOpacity:   .2,
                strokeColor:   '#855487',
                strokeOpacity: .9,
                strokeWeight:  2
            }
        },
        markerLabelOptions: {
            draggable:   false,
            raiseOnDrag: false,
            labelAnchor: typeof google !== 'undefined' ? new google.maps.Point(0,40) : null,
            labelClass:  'map__markers',
            labelStyle:  {
                opacity: 1
            },
            icon:    ' ',
            visible: false,
            active:  false
        },
        queryStr: document.getElementById('planning-scheme__map') 
            ? document.getElementById('planning-scheme__map').getAttribute('data-map-query') 
                ? document.getElementById('planning-scheme__map').getAttribute('data-map-query')
                : 'f.Scheme%7CplanningSchemeName'
            : '',
        actionUrl: document.getElementById('planning-scheme__map') 
            ? document.getElementById('planning-scheme__map').getAttribute('data-map-actionUrl')
                ? document.getElementById('planning-scheme__map').getAttribute('data-map-actionUrl')
                : ''
            : '',
        queryVal: 'undefined',

        initialize: function() {

            this.australiaMap = new google.maps.Map(this.mapWrapper, this.mapOptions);

            this.googlePrototypeGetBounds();

            this.checkGetParam();

            this.getMapData();
        },

        getMapData: function() {
            fetch(planningSchemeMap.mapDataUrl).then(function(response) {
                return response.json();
            }).then(function(jsonData) {
                planningSchemeMap.areas(jsonData);
            });
        },

        areas: function(fetchData) {
            const mapData = fetchData;

            mapData.map(area => {

                const coordinates = area.boundaries.map(coordinate => {
                    return new google.maps.LatLng(coordinate[1], coordinate[0]);
                });
                
                const victoriaLocalGovernmentAreas = new google.maps.Polygon(Object.assign({
                    paths: coordinates
                }, planningSchemeMap.areaOptions.init));

                const markerLabel = new MarkerWithLabel(Object.assign({

                    position:     victoriaLocalGovernmentAreas.getBounds().getCenter(),
                    map:          planningSchemeMap.australiaMap,
                    labelContent: area.name.toLocaleLowerCase()

                }, planningSchemeMap.markerLabelOptions));

                //draw Goverment Areas
                victoriaLocalGovernmentAreas.setMap(planningSchemeMap.australiaMap);

                //activate area from get parametr 
                planningSchemeMap.activeArea(victoriaLocalGovernmentAreas, markerLabel);

                //add event listeners
                planningSchemeMap.eventListeners(victoriaLocalGovernmentAreas, markerLabel);

            });
        },

        eventListeners: function(area, label) {

            google.maps.event.addListener(area, 'mouseover', function() {
                planningSchemeMap.mouseoverHandler(this, label);
            });

            google.maps.event.addListener(area, 'mouseout', function() {
                planningSchemeMap.mouseoutHandler(this, label);
            });

            google.maps.event.addListener(area, 'click', function() {
                planningSchemeMap.clickHandler(this, label);
            });

            google.maps.event.addListener(label, 'click', function() {
                planningSchemeMap.clickHandler(area, this);
            });
        },

        mouseoverHandler: function(area, label) {
            if(!label.active) {
                area.setOptions(planningSchemeMap.areaOptions.hover);
                label.setVisible(true);
            }
        },

        mouseoutHandler: function(area, label) {
            if(!label.active) {
                area.setOptions(planningSchemeMap.areaOptions.defaults);
                label.setVisible(false); 
            }
        },

        clickHandler: function(area, label) {
            if (planningSchemeMap.queryVal.toLocaleLowerCase() !== label.labelContent) {
                if (planningSchemeMap.queryVal.toLocaleLowerCase() !== label.labelContent && label.labelContent.includes('alpine resort')) {
                    window.location = planningSchemeMap.actionUrl + '?' + planningSchemeMap.queryStr + '=' + 'Alpine+Resorts';
                    area.setOptions(planningSchemeMap.areaOptions.defaults);
                    label.setVisible(false);
                } else {
                window.location = planningSchemeMap.actionUrl + '?' + planningSchemeMap.queryStr + '=' + planningSchemeMap.toTitleCase(label.labelContent).replace(' ', '+');
                area.setOptions(planningSchemeMap.areaOptions.defaults);
                label.setVisible(false);
                }
            }
        },

        checkGetParam: function() {
            const query = window.location.search.substring(1);
            query.split('&').map(param => {
                if(param.split('=')[0] === planningSchemeMap.queryStr) {
                    if (param.split('=')[1].toString().includes("+")) {
                        planningSchemeMap.queryVal = param.split('=')[1].replace('+',' ');
                    } else if (param.split('=')[1].toString().includes("-")) {
                        planningSchemeMap.queryVal = param.split('=')[1].replace('-',' ');
                    } else {
                        planningSchemeMap.queryVal = param.split('=')[1].replace('%20',' ');
                    }
                }
            });
        },

        activeArea: function(area, label) {
            if (planningSchemeMap.queryVal.toLocaleLowerCase() === label.labelContent) {
                label.setVisible(true);
                label.active = true;
                area.setOptions(planningSchemeMap.areaOptions.active);
            }
        },

        toTitleCase: function(str) {
            return str.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();});
        },

        googlePrototypeGetBounds: function() {
            google.maps.Polygon.prototype.getBounds = function () {
                const bounds = new google.maps.LatLngBounds();
                this.getPath().forEach(function (el) { 
                    bounds.extend(el); 
                });
                return bounds;
            };
        }
    };

    if (document.getElementById('planning-scheme__map')) {
        planningSchemeMap.initialize();
    }

}());