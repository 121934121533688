(function($){
    'use strict';
    $('.carousel__item-container')
        .on('init breakpoint', function(e, slick){
            window.setTimeout(function(){
                $('.carousel-nav').find('li').attr('role', null)
            }, 500)
        })
        .slick({
            dots: true,
            useTransform: false,
            dotsClass:'carousel-nav',
            appendDots: $('.carousel__panels'),
            customPaging: function(slick, index){
                var content = $(slick.$slides[index]).find('.carousel__hidden-content').html().replace('tabindex="-1"','')
                return content 
            },
            responsive: [{
                breakpoint: 768,
                settings: {
                    arrows: false,
                    customPaging: function(slick, index){
                        return  '<button type="button" data-role="none" data-slick-index="' + index + '">' +
                            index +
                        '</button>'
                    },
                }
            }
            // ,{
            //     breakpoint: 1360,
            //     settings: {
            //         arrows: false
            //     }
            // }
            ]
        })


    $('.carousel').on('click','.carousel-nav__item', function(e){
        e.preventDefault()
    })

}(jQuery));