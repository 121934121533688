(function($){
    'use strict';
// prevent submit form by pressing enter on keyboard 
if ($('#search__query-autocomplete').length > 0 ) {
    document.querySelector('#search__query-autocomplete').addEventListener('keypress', function(e) { 
        if( e.keyCode === 13 ){
             e.preventDefault(); 
             return false; 
            }
    });
}
    
}(jQuery));