(function($){
    'use strict';

    const lgaAutocomplete = {
        sel: {
            lga: '.lga-selector',
            search: '#lga-selector',
            searchMenu: '.lga-selector--menu',
            searchHint: '.lga-selector--hint',
            searchDataset: '.lga-selector--dataset',
            searchCursor: '.lga-selector--cursor',
            main: '.main',
            searchCancel: '.lga-selector__close',
            submitForm: '.lga-selector__submit',
            form: '.lga-selector form',
            scheme: '.right-box__planning-scheme-link'
        },
        vars: {
            classNames: 'lga-selector',
            url: $('#lga-selector').attr('data-typeahead'),
            wideBreakpoint: 1024,
            mediumBreakpoint: 768,
            arrowSelect: true,
            emptyResult: 'No matching<br>Scheme or suburb found.',
            fetchedAlready: false,
            keys: {
                ENTER: 13,
                KEY_DOWN: 40,
                KEY_UP: 38
            }
        },
        source: [],
        init() {
            const self = this;
            this.changeMobileTexts();
            if (!this.vars.fetchedAlready) {
                this.vars.fetchedAlready = true;
                this.postInit();
            }
        },
        postInit() {
            const self = this;
            this.fetchData(function() {
                self.initTypehead();
                self.setAutosuggestionWidth();
                self.onFocus();
                self.clearInput();
                self.sendQuery();
                self.scrollOnArrowKeys();
            });
        },
        initTypehead() {
            const self = this;
            $(this.sel.search).typeahead({
                name: 'lga-search',
                hint: true,
                highlight: true,
                minLength: 1,
                classNames: {
                    input: self.vars.classNames + '--input',
                    hint: self.vars.classNames + '--hint',
                    menu: self.vars.classNames + '--menu',
                    dataset: self.vars.classNames + '--dataset',
                    suggestion: self.vars.classNames + '--suggestion',
                    empty: self.vars.classNames + '--empty',
                    open: self.vars.classNames + '--open',
                    cursor: self.vars.classNames + '--cursor',
                    highlight: self.vars.classNames + '--highlight',
                    selectable: self.vars.classNames + '--selectable'
                }
            },
            {
                name: 'data',
                async: false,
                limit: 100,
                source: self.substringMatcher(self.source),
                display: function(data) {
                    let $data = $('<div>' + data + '</div>');
                    $data.find('span').remove();
                    data = $data.text().trim();
                    return data;
                },
                templates: {
                    suggestion: function(data) {
                        return `<div>${data}</div>`;
                    },
                    notFound: function() {
                        return `<div class="${self.vars.classNames}--empty">${self.vars.emptyResult}</div>`;
                    }
                }
            }).on('typeahead:render', function(){
                $($('.lga-selector--dataset > .lga-selector--selectable').get().reverse()).each(function () {
                    if ($(this).find('span strong').length) {
                        $(this).prependTo('.lga-selector--dataset');
                    }
                });
            });
        },
        fetchData(cb) {
            const self = this,
                  url = this.vars.url;
            fetch(url)
                .then(res => res.json())
                .then((res) => {
                    self.source = self.parseData(res);
                    cb();
                }
            );
        },
        parseData(data) {
            let i, tempData, parsedData = [];
            for (i = 0; i < data.length; i++) {
                if (data[i].name.length > 0) {
                    tempData = `<span>${data[i].code}</span> ${data[i].name}`;
                } else {
                    tempData = `<span class="scheme">${data[i].code}</span>`;
                }
                parsedData.push(tempData);
            }
            return parsedData;
        },
        setAutosuggestionWidth() {
            if (window.innerWidth < this.vars.wideBreakpoint) {
                $(this.sel.searchMenu).css({
                    'width': $(this.sel.main).width() + 'px',
                    'left': '-10px'
                });
            }
        },
        onFocus() {
            $(this.sel.search).on('focus', () => {
                if($(this.sel.search).typeahead('val')) {
                    $(this.sel.searchCancel).addClass(this.vars.classNames + '__close--focus');
                    $(this.sel.submitForm).addClass(this.vars.classNames + '__submit--focus');
                } else {
                    $(this.sel.searchCancel).removeClass(this.vars.classNames + '__close--focus');
                    $(this.sel.submitForm).removeClass(this.vars.classNames + '__submit--focus');
                }
            });
            $(this.sel.search).on('focusout', () => {
                if (!$(this.sel.search).typeahead('val')) {
                    $(this.sel.searchCancel).removeClass(this.vars.classNames + '__close--focus');
                    $(this.sel.submitForm).removeClass(this.vars.classNames + '__submit--focus');
                } else {
                    $(this.sel.searchCancel).addClass(this.vars.classNames + '__close--focus');
                    $(this.sel.submitForm).addClass(this.vars.classNames + '__submit--focus');
                }
            });
        },
        clearInput() {
            $(this.sel.searchCancel).on('click', () => {
                $(this.sel.search).val('');
                $(this.sel.searchHint).val('');
                $(this.sel.search).typeahead('val', '');
                $(this.sel.search).focus();
                this.vars.arrowSelect = true;
            });
        },
        sendQuery() {
            const self = this;
            $(this.sel.search).bind('typeahead:select', function (e, suggestion) {
                suggestion = $('<div>' + suggestion + '</div>');

                suggestion = suggestion.find('span').text().trim();
                $(this).val(suggestion);
                $(self.sel.form).submit();
            });
        },
        scrollOnArrowKeys() {
            const self = this,
                  dataSet = $(this.sel.searchDataset),
                  constSelectableHeight = 63;
            let cursor, storeResult;

            $(this.sel.search).bind('keypress', function () {
                if (!$(this).val().length) {
                    self.vars.arrowSelect = true;
                }
            });
            $(this.sel.search).bind('keydown', function (e) {
                cursor = $(self.sel.searchCursor);
                storeResult = cursor.length ? parseInt(cursor.css('height'), 10) : constSelectableHeight;
                if ($(this).length && cursor.length && !self.vars.arrowSelect) {
                    if(e.which === self.vars.keys.KEY_DOWN){
                        dataSet[0].scrollTop = dataSet[0].scrollTop + storeResult;
                    }
                    if(e.which === self.vars.keys.KEY_UP){
                        dataSet[0].scrollTop = dataSet[0].scrollTop - storeResult;
                        if (dataSet[0].scrollTop <= 0) {
                            dataSet[0].scrollTop = 0;
                        }
                    }
                } else if (cursor.length) {
                    self.vars.arrowSelect = false;
                    dataSet[0].scrollTop = 0;
                } else {
                    dataSet[0].scrollTop = 0;
                }
            });
        },
        substringMatcher(strs) {
            return function findMatches(q, cb) {
                var matches, substringRegex, substrRegex;
                matches = [];
                substrRegex = new RegExp(q, 'i');
                $.each(strs, function(i, str) {
                    if (substrRegex.test(str)) {
                    matches.push(str);
                    }
                });
                cb(matches);
            }
        },
        changeMobileTexts: function() {
            const mobilePlaceholder = $(this.sel.search).attr('data-mobile-placeholder') || '',
                  desktopPlaceholder = $(this.sel.search).attr('data-desktop-placeholder') || '',
                  mobileText = $(this.sel.scheme).attr('data-mobile-text') || '',
                  desktopText = $(this.sel.scheme).attr('data-desktop-text') || '',
                  self = this;

                if ($( window ).width() < this.vars.mediumBreakpoint) {
                    $(this.sel.search).attr('placeholder', mobilePlaceholder);
                    $(this.sel.scheme).text(mobileText);
                }

                $( window ).resize(function() {
                    if ($( window ).width() < self.vars.mediumBreakpoint) {
                        $(self.sel.search).attr('placeholder', mobilePlaceholder);
                        $(self.sel.scheme).text(mobileText);
                    } else {
                        $(self.sel.search).attr('placeholder', desktopPlaceholder);
                        $(self.sel.scheme).text(desktopText);
                    }
            });
        }
    };

    if ($('#lga-selector').length) {
        lgaAutocomplete.init();
    }

    // show map
    $(document).on("click", "#planning-scheme__show-map, .search-header__select-planning-btn, .top-bar__button--map", function(e) {
        e.preventDefault();
        $(this).closest("section").find(".map-container").addClass("active");
        $(this).closest('section').find('#schemes-amendments-map').addClass('active');
        $(this).attr('id') === 'planning-scheme__show-map' ? $('html').animate({ scrollTop: $('#planning-scheme__show-map').offset().top + 122}, 1000) : '';
    });

    // hide map
    $(document).on("click", ".hide-map-button", function(e) {
        e.preventDefault();
        $(this).closest(".map-container").removeClass("active");
        $(this).closest('section').find('#schemes-amendments-map').removeClass('active');
    });

    // lga form popup
    // show lga form
    $(document).on("click",".search-header__change-plnning-btn", function(e) {
        e.preventDefault();
        $(this).closest("section").find(".lga-popup-container").toggleClass("active");
        $(this).closest("section").find(".lga-popup-container input").focus();
        $(this).find(".search-header__change-plnning-icon").toggleClass("open");

    });

    // hide lga form
    $(document).on("click", ".lga-popup-container__close", function(e) {
        e.preventDefault();
        $(this).closest(".lga-popup-container").removeClass("active");
        $(".search-header__change-plnning-icon").removeClass("open");
    });

    // move autocomplete box outside of lga form
    $( ".lga-popup-container #lga-selector" ).focus(function() {
        $(".lga-selector--menu").appendTo(".lga-selector__content-wrapper");
    });
    
}(jQuery));

